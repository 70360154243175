button {
  font-family: inherit;
}

.button {
  border-radius: 4px;
  font-size: 13px;
  font-weight: 800;
  padding: 10px;
  text-transform: uppercase;
  outline: none !important;
}

.button-secondary-color {
  background: linear-gradient(
    143.27deg,
    $colorMainButtonGradientStart 0%,
    $colorMainButtonGradientEnd 100%
  );
  color: $colorTextLightest;
  cursor: pointer;
  border: none;
}

.button-secondary-color:hover {
  background: $colorMainButtonGradientEnd;
}

.button-main-change {
  cursor: pointer;
  color: $colorTextLink;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid $colorBorderLink;
  padding: 10px 10px;
  background: transparent;
  font-weight: 800;
}

.button-main-search {
  border: none;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 0.84px;
  line-height: 20px;
  text-transform: uppercase;
  height: 60px;
  width: 100%;
  outline: none !important;
  margin-top: 11px;
  @media (min-width: $desktop) {
    width: 190px;
    margin-top: inherit;
  }
  &.iframe {
    width: 190px;
    margin-top: inherit;
  }
}

.container[max-width~="960px"] .button-main-search {
  width: 100%;
  margin-top: 10px;
}

.button-back-result {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 20px;
  cursor: pointer;
  top: 13px;
}

.label-button-back-result {
  margin-left: 10px;
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 0.84px;
}

.button-special-offers-offer {
  padding: 17px 23px;
  margin: 15px auto;
  text-transform: uppercase;
  border-radius: 4px;
}

.button-confirm {
  border: none;
  font-weight: normal;
  font-size: 12px;
  padding: 20px 30px;
}

.reservation-detail-action-button {
  text-align: center;
  min-width: 100px;
  display: inline-block;
  font-size: 13px;
  cursor: pointer;
  padding: 16px 24px;
  border-radius: 4px;
  font-weight: 800;
  min-width: 150px;
  border: none;
  background: linear-gradient(
    143.27deg,
    $colorMainButtonGradientStart 0%,
    $colorMainButtonGradientEnd 100%
  );
  color: $colorTextLightest;
  font-family: Muli, Arial, sans-serif;
}

.my-profile-confirm-button {
  padding-top: 15px;
  padding-bottom: 15px;
  @media (min-width: $desktop) {
    margin-left: 160px;
  }
}

.sso-button {
  width: 100%;
  max-width: 320px;
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  @media (min-width: 670px) {
    width: 320px;
  }
  padding: 13px 17px;
  border-radius: 3px;
  color: $colorMainButtonGradientStart;
  font-weight: 800;
  background: $colorTextLightest;
  border: 1px solid $colorMainButtonGradientStart;
  @media (min-width: $desktop) {
    //width: 320px;
  }
}

.sso-button:hover {
  color: $colorTextLightest;
  background: linear-gradient(
    143.27deg,
    $colorMainButtonGradientStart 0%,
    $colorMainButtonGradientEnd 100%
  );
}

.primary-button {
  text-align: center;
  min-width: 100px;
  display: inline-block;
  font-size: 13px;
  cursor: pointer;
  padding: 20px 17px;
  border-radius: 4px;
  color: $colorTextLightest;
  font-weight: 800;
  background: linear-gradient(
    143.27deg,
    $colorMainButtonGradientStart 0%,
    $colorMainButtonGradientEnd 100%
  );
  border: none;
}

.primary-button:hover {
  background: $colorMainButtonGradientEnd;
}

.secondary-button {
  @extend .primary-button;
  background: $colorBackgroundLightest;
  border: 1px solid $colorMainButtonGradientStart;
  color: $colorMainButtonGradientStart;
  &:hover {
    background-color: $colorBackgroundWarningLight;
  }
}

.go-back-button {
  text-align: center;
  min-width: 100px;
  display: inline-block;
  font-size: 13px;
  cursor: pointer;
  padding: 16px 24px;
  border-radius: 4px;
  font-weight: 800;
  border: none;
  background: linear-gradient(
    143.27deg,
    $color-light-gray-02 -20%,
    $color-dark-gray-03 120%
  );
  color: $colorTextLightest;
  &:hover {
    background: linear-gradient(
      143.27deg,
      $color-dark-gray-06 -20%,
      $color-dark-gray-04 120%
    );
  }
  .go-back-button_arrow {
    margin-right: 16px;
  }
}

.cancel-reservation-button {
  text-align: center;
  min-width: 100px;
  display: inline-block;
  font-size: 13px;
  cursor: pointer;
  padding: 16px 24px;
  border-radius: 4px;
  font-weight: 800;
  border: none;
  background: linear-gradient(
    143.27deg,
    $color-light-red-01 -20%,
    $color-dark-red-01 120%
  );
  color: $colorTextLightest;
  &:hover {
    background: $color-dark-red-02;
  }
}

.checkbox {
  display: flex;
  width: 100%;
  align-items: center;
  &.disabled {
    opacity: 0.4;
    .checkbox-label {
      cursor: not-allowed;
    }
  }
}

.checkbox-label {
  font-size: 14px;
  user-select: none;
  cursor: pointer;
  margin-left: 8px;
}

.checkbox-swap-order {
  order: -1;
  margin-left: 0;
  margin-right: 8px;
}

.checkbox-background {
  background-color: $colorCheckboxBackground;
  border-radius: 3px;
}

.custom-checkbox-container {
  height: 45px;
  display: flex;
  align-items: center;
}

.custom-checkbox {
  justify-content: flex-start;
}

.custom-checkbox .pointer {
  margin-right: 1rem;
}

.create-new-password-button {
  margin-top: 35px;
}
