.header {
  padding-top: 20px;

  @media (min-width: $desktop) {
    padding-top: 40px;
  }
}

.header-container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.separator-dotted {
  border-left: 2px dotted $colorBorderAdditionalLight;
  margin: 0 14px;
  height: inherit;
  display: none;
  @media (min-width: $desktop) {
    display: block;
  }
}

.header-links-container {
  display: none;
  float: right;
  height: 22px;
  align-items: center;
  margin-top: 17px;
  font-size: 14px;
  @media (min-width: $desktop) {
    display: flex;
    max-width: 600px;
  }
}

.header-logo-image {
  height: 58px;
  margin: 0px 0 0 10px;
  float: left;
  max-width: 200px;

  @media (min-width: $desktop) {
    height: 79px;
    float: left;
    margin-right: 16px;
    max-width: 300px;
  }
}

.header-logo-slogan {
  color: $colorTextDarkest;
  font-size: 15px;
  // font-weight: bold;
  line-height: 22px;
  margin-top: 17px;
  display: none;
  @media (min-width: $desktop) {
    display: inline-block;
    margin-right: 20px;
  }
}

a .header-slogan {
  text-decoration: none;
}

.header-mobile-buttons {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (min-width: $desktop) {
    display: none;
  }
}

.header-menu-link {
  float: right;
  height: 60px;
  width: 105px;
  border-radius: 5px;
  background-color: $colorBackgroundLightest;
  box-shadow: 0 3px 7px 0 $colorBackgroundShade1;
  padding-top: 12px;
  padding-left: 15px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  z-index: 2;
  margin-right: 10px;
}

.header-mobile-logout {
  margin-right: 15px;
}

.header-signin-mobile-button {
  margin-right: 10px;
  display: flex;
  overflow: hidden;
}

.header-signin-mobile-text {
  @media (max-width: $desktop) {
    max-width: 200px;
  }
}

.header-menu-link-hamburger {
  color: $colorTextLink;
  font-size: 22px;
  position: relative;
  top: 2px;
  right: 5px;
  width: 30px;
  display: inline-block;
  height: 30px;
  // border: 1px solid $color-text-warning;
  text-align: center;
}

.header-menu-link-text {
  top: 20px;
  position: absolute;
}

.header-menu-currencies,
.header-menu-languages {
  display: none;
  user-select: none;
  @media (min-width: $desktop) {
    display: flex;
    align-items: center;
  }
}

.header-menu-currencies-selected,
.header-menu-languages-selected {
  cursor: pointer;
  font-size: 14px;
}

.header-menu-currencies-ico,
.header-menu-languages-ico {
  margin: 0 0 0 10px;
  width: 9px;
  height: 11px;
  position: relative;
  top: 1px;
}

.header-menu-selected-currency-ico {
  margin: 0 10px 0 0;
}

.header-menu-currency-option-ico {
  margin: 0 10px -4px 0;
}

.header-menu-tooltip-text {
  display: inline-block;
  &:hover {
  }
}

.header-menu-tooltip {
  font: 13px/19px Muli;
  background: $colorBackgroundLightest;
  border-radius: 5px;
  box-shadow: 0 0 13px 0 #{$colorBackgroundAdditionalLight}59;
  margin: 10px 10px 10px 35px;
  top: 85px;
  min-width: 130px;
  float: right;
  position: absolute;
  animation: fadeIn 0.5s ease;
  padding: 5px;
}

.header-menu-tooltip div {
  display: block;
  padding: 3px 15px;
  cursor: pointer;
  border-bottom: 1px dotted $colorBorderBackgroundShade1;

  &:hover {
    background-color: $colorBackgroundLanguageSelection;
  }

  &:last-child {
    border-bottom: none;
  }
}

.header-menu-currencies-tooltip,
.header-menu-languages-tooltip {
  background: $colorBackgroundLightest;
  padding: 5px 0;
  border-radius: 5px;
  box-shadow: 0 0 13px 0 #{$colorBackgroundAdditionalLight}59;
  margin: 5px 5px 0 0;
  position: absolute;
  animation: fadeIn 0.5s ease;
  top: 85px;
  z-index: 999;
}

.header-menu-currencies-tooltip a,
.header-menu-languages-tooltip a {
  display: block;
  padding: 3px 15px;
  cursor: pointer;
  border-bottom: 1px dotted $colorBorderBackgroundShade1;

  &:hover {
    background-color: $colorBackgroundLanguageSelection;
  }

  &:last-child {
    border-bottom: none;
  }
}

.header-signin-image {
  cursor: pointer;
  float: left;
  margin-right: 12px;
  margin-left: 2px;
}

.header-signin-image--small {
  float: left;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.header-button {
  text-decoration: none;
  display: flex;
}

.header-button-text {
  cursor: pointer;
  letter-spacing: 0.6px;
  color: $colorText;
  margin-top: 6px;
  display: none;
  user-select: none;
  @media (min-width: $desktop) {
    display: flex;
    margin: 0;
    align-items: center;
    margin-right: 2px;
  }

  &:hover {
    text-decoration: underline;
  }
}

#header-reservations-button {
  @media (min-width: $desktop) {
    white-space: nowrap;
  }
}

.header-signin-logout {
  cursor: pointer;
  color: $colorTextAdditionalLight;
  font-size: 13px;
  float: right;
  display: inline-block;
  margin-top: 6px;
  margin-left: 4px;
}

.header-mobile-menu {
  width: 100%;
  background: $colorBackgroundLightest;
  margin-top: 20px;
  position: absolute;
  text-align: center;
  // padding: 22px;
  box-sizing: border-box;
  // display: none;
  max-height: 0px; /* approximate max height */
  overflow-y: hidden;
}

.header-mobile-menu-link {
  display: block;
  padding-top: 15px;
  padding-bottom: 15px;
  color: $colorText;

  &.underline {
    text-decoration: underline;
  }
}

.header-mobile-menu-link:first-of-type {
  margin-top: 10px;
}

.header-mobile-menu-link:last-of-type {
  margin-bottom: 10px;
}

.header-mobile-menu-show {
  // display: block;
  overflow-y: hidden;
  max-height: 500px; /* approximate max height */

  transition-property: max-height;
  transition-duration: 2s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  z-index: 2;
}

.header-mobile-menu-background {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  opacity: 0.8;
  background-color: $colorBackgroundAdditionalDark;
  z-index: 1;
  animation: fade80 1s ease;
}

@keyframes fade80 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header-h1 {
  clear: both;
  text-align: center;
  font-weight: 400;
  margin-top: 41px;
}

.header-search-form {
  margin-top: 40px;

  @media (min-width: $desktop) {
    .preferences {
      width: 36%;
    }
  }
}

.cbt-preferences {
  @media (max-width: $desktop) {
    margin-bottom: 30px;
  }
}

.header-search-form-tabs {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-search-form-tab-flight-container {
  display: flex;
  align-items: center;

  @media (min-width: $desktop) {
    flex-direction: row;
  }
}

.header-search-form-tab {
  display: inline-block;
  width: 31%;
  height: 48px;
  border-radius: 5px 5px 0 0;
  background-color: $colorBackgroundShade1;
  padding: 17px 15px 0 15px;
  margin-right: 10px;
  text-align: center;
  box-sizing: border-box;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  @media (min-width: $desktop) {
    width: auto;
    padding: 17px 20px 0 20px;
  }
  &.iframe {
    @media (min-width: $desktop-iframe) {
      width: auto;
      padding: 17px 20px 0 20px;
    }
  }
}

.container[max-width~="960px"] .header-search-form-tab {
  width: 31%;
}

.header-search-form-tab-last {
  margin-right: 0 !important;
}

.header-search-form-tab--active {
  background: $colorBackgroundLightest;
  transition: all 0.2s ease-out;
}

.header-search-form-tab-hotels {
  width: 140px;
  @media (max-width: $desktop) {
    width: 80px;
  }
}

.header-search-form-tabs-with-hotels {
  @media (max-width: $desktop) {
    display: flex;
    justify-content: space-between;
  }

  .header-search-form-tab {
    align-items: center;
    justify-content: center;
    padding: 0px 5px;
    display: flex;
  }

  .header-search-form-tab-text {
    min-height: 0;
  }

  @media (min-width: $desktop) {
    .header-search-form-tab {
      align-items: inherit;
      justify-content: inherit;
      padding: 17px 20px 0 20px;
      display: inline-block;
    }

    .header-search-form-tab-text {
      min-height: 48px;
    }
  }
}

.react-select-hotels__value-container {
  padding-top: 10px !important;

  @media (min-width: $desktop) {
    padding-top: 0 !important;
  }
}

.header-search-form-tab--change-type-search {
  float: right;
  margin-right: 0;
  //background: linear-gradient(
  //  143.27deg,
  //  rgb(255, 133, 49) 0%,
  //  rgb(219, 74, 23) 100%
  //);
  //color: white;
  //font-weight: bold;
  // background: white;
  border: none; // 1px solid $colorMainButtonGradientStart;
  border-bottom: none; // 1px dotted $colorMainButtonGradientStart;
  color: $colorMainButtonGradientEnd !important;
  font-weight: bold;
  &:hover {
    background-color: $colorBackgroundLightest;
  }
}

.header-search-form-tab-ico-return {
  display: none;
  float: left;
  position: relative;
  top: -5px;
  @media (min-width: $desktop) {
    display: block;
  }

  &.iframe {
    @media (min-width: $desktop-iframe) {
      display: block;
    }
  }
}

.container[max-width~="960px"] .header-search-form-tab-ico-return {
  display: none;
}

.header-search-form-tab-ico {
  display: block;
  float: left;
  @media (max-width: $desktop) {
    display: none;
  }

  &.iframe {
    @media (max-width: $desktop-iframe) {
      display: none;
    }
  }
}

.container[max-width~="960px"] .header-search-form-tab-ico {
  display: none;
}

.header-search-form-tab-text {
  color: $colorText;
  max-width: 200px;
  transition: all 0.2s ease-out;
  min-height: 20px;
  user-select: none;
  font-weight: bold;
  @media (min-width: $desktop) {
    margin-left: 10px;
  }

  &.iframe {
    @media (min-width: $desktop-iframe) {
      margin-left: 10px;
    }
  }
}

.container[min-width~="960px"] .header-search-form-tab-text {
  margin-left: 10px;
}

.header-search-form-tab-text-active {
  display: inline;
}

.select-menu-outer {
  box-shadow: 0 0 7px 0 #{$colorBackgroundAdditionalLight}4c !important;
}

.header-search-form-results {
  .select-menu-outer {
    z-index: 1;
    border: none;
    animation: fadeIn 0.2s ease;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    max-height: 200px;
    min-width: 330px;
    position: absolute;
    width: 340px;
    padding-left: 5px;
    margin-left: -5px;
    @media (min-width: $desktop-iframe) {
      padding-top: 0;
      padding-left: 0;
      margin: 0px 0px 0px -20px;
      min-width: 300px !important;
      width: 415px;
    }
  }

  .Select-clear {
    display: none;
  }
}

.container[min-width~="960px"] .header-search-form-results .select-menu-outer {
  padding-top: 0;
  padding-left: 0;
  margin: 0px 0px 0px -20px;
  min-width: 300px !important;
  width: 415px;
}

.cost-center-font {
  font-family: Muli, Arial, sans-serif !important;
}

.cost-center-selected {
  color: $colorText !important;
  text-transform: uppercase;
  font-weight: bold;
}

.passenger-options-button-text-icon-indentation {
  margin-left: 18px;
}

.passenger-options-button-arrow-icon-indentation {
  margin-right: 2px;
}

.cost-center-placeholder {
  color: hsl(0, 0%, 50%);
}

.header-search-form-option {
  padding: 7px 10px 7px 5px;
  @media (min-width: $desktop-iframe) {
    padding: 7px 10px 7px 20px !important;
  }
  cursor: pointer;
  color: $colorTextDarkest;
  font-size: 15px;

  &:hover {
    background: $colorBackgroundShade1;
  }
}

.header-search-form-option-mobile {
  padding-left: 20px !important;
}

.header-search-form-option-selected {
  background: $colorBackgroundShade1;
}

.container[min-width~="960px"] .header-search-form-option {
  padding: 7px 10px 7px 20px !important;
}

.header-search-form-option-selected {
  background: $colorBackgroundShade1 !important;
}

.header-search-form-inner {
  background: $colorBackgroundLightest;
  border-radius: 0px 5px 0 0;
  padding: 30px 20px 0 20px;
  box-sizing: border-box;
}

.header-search-form-inner-cols {
  display: flex;
  @media (max-width: $desktop) {
    flex-direction: column;
  }
}

.container[max-width~="960px"] .header-search-form-inner-cols {
  flex-direction: column;
}

.header-search-form-inner-first-col {
  width: 100%;
  @media (min-width: $desktop) {
    width: 64%;
  }
}
.container[max-width~="960px"] .header-search-form-inner-first-col {
  width: 100%;
}

.header-search-form-inner-field {
  flex: 1;
  margin-bottom: 30px;
  @media (min-width: $desktop) {
    margin-bottom: 25px;
  }
}

.header-search-form-inner-field-label {
  display: block;
  font-size: 13px;
  margin-bottom: 8px;
  color: $colorTextAdditionalLight;
}

.header-search-form-inner-field-text {
  display: flex;
  align-items: baseline;
  border: none;
  outline: none;
  background-color: transparent;
  text-align: left;
  font-size: 17px;
  max-width: 215px;
  color: $colorTextAdditionalDark;
  padding: 2px 0 0;
  cursor: pointer;

  &.form-filled {
    max-width: initial;
  }
}

.header-search-form-additional {
  background-color: $colorBackgroundShade1;
  border-radius: 0px 0 5px 5px;

  @media (min-width: $desktop) {
    display: none;
  }

  &.iframe {
    @media (min-width: $desktop-iframe) {
      display: none;
    }
  }
}

.container[max-width~="960px"] .header-search-form-additional {
  display: block;
}

.header-search-form-additional-desktop {
  display: none;
  background-color: $colorBackgroundShade1;
  border-radius: 0px 0 5px 5px;

  @media (min-width: $desktop) {
    animation: fadeIn 0.5s ease;
    opacity: 0;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    display: flex;
    align-items: center;
    padding: 14px 12px;
    height: 60px;
  }

  &.iframe {
    @media (min-width: $desktop-iframe) {
      animation: fadeIn 0.5s ease;
      opacity: 0;
      animation-delay: 1s;
      animation-fill-mode: forwards;
      display: flex;
      align-items: center;
      padding: 20px 10px 20px 10px;
      height: 60px;
    }
  }
}

.minimized-header-search-desktop {
  animation: fadeIn 0.5s ease;
  opacity: 0;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  background-color: #e3edf3;
  align-items: center;
  padding: 10px 20px;
}

.minimized-header-flight-segment {
  padding: 5px 5px 5px 5px;
  border-radius: 16px;
  &:hover {
    cursor: pointer;
    background: $colorBackground;
  }
}

.container[max-width~="960px"] .header-search-form-additional-desktop {
  display: none;
}

.header-search-form-additional-desktop-one {
  padding-left: 20px;
  padding-right: 20px;
  height: 56px;
  display: flex;
  align-items: center;
  border-right: 1px solid $colorBorderSearchFormAdditional;
}

.header-search-form-additional-desktop-one .checkbox-label,
.header-search-form-additional-mobile-one .checkbox-label {
  margin-left: 15px;
}

.header-search-form-additional-desktop-one-only-direct {
  padding-left: 5px;
}

.header-search-form-additional-desktop-one-airlines {
  padding-right: 12px;
}

.header-search-form-additional-desktop-one-tolerance {
  font-size: 14px;
}

.header-search-form-additional-desktop-one-tolerance-inner {
  display: flex;
  align-items: center;
}

.header-search-form-additional-desktop-one-tolerance-inner-label {
  margin-right: 10px;
}

.header-search-form-additional-desktop-one-direct-flights {
  @extend .header-search-form-additional-desktop-one;
  max-width: 150px;
}

.filter-bar-airlines-iframe {
  @media (max-height: $mobile-iframe-height + 130) and (max-width: $desktop-iframe - 1) {
    margin-top: -235px !important;
  }
  @media (max-height: $desktop-iframe-height) and (min-width: $desktop-iframe) {
    margin-top: -235px !important;
  }
}

.container[max-height~="790px"]
  and
  .container[max-width~="960px"]
  .filter-bar-airlines-iframe {
  margin-top: -235px !important;
}

.search-airlines {
  color: $colorText !important;
}

.search-airlines--filled {
  color: $colorTextLink !important;
}

.header-search-form-additional-desktop-one-search-button {
  flex-grow: 1;
  justify-content: flex-end;
  padding-right: 0px !important;
  display: flex;
  @media (min-width: $desktop) {
    flex-grow: 1;
    justify-content: flex-end;
    padding-right: 10px;
    display: flex;
  }
}

.header-search-form-inner-field-value {
  font-size: 19px;
  font-weight: bold;
  margin-right: 5px;
}

.header-search-form-inner-field-additional {
  font-size: 19px;
  font-weight: 400;
  color: $colorTextAdditionalDark;
}

.no-border {
  border: none;
}

.header-search-form-additional-top {
  border-bottom: 1px solid $colorBorderSearchFormAdditional;
  height: 60px;
}

.header-search-form-additional-top-second {
  height: 60px;
  width: 55%;
  float: left;
  padding: 14px;
  box-sizing: border-box;
  clear: right;
}

.header-search-form-additional-top-first {
  border-right: 1px solid $colorBorderSearchFormAdditional;
  height: 60px;
  width: 45%;
  float: left;
  padding: 14px;
  box-sizing: border-box;
  display: flex;
}

.header-search-form-additional-bottom {
  padding: 14px 20px;
  box-sizing: border-box;
  background-color: $colorBackgroundShade1;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.header-search-form-additional-bottom-h {
  display: inline-block;
  margin-bottom: 15px;
}

.header-search-form-inner-field-ico {
  /*margin: 0 0 0 20px;*/
  width: 11px;
  height: 11px;
  position: absolute;
  margin-left: 9px;
  margin-top: 7px;
}

.header-search-form-properties-top {
  margin-top: -370px !important;
}

.form-properties-wrapper {
  cursor: pointer;
  display: inline-flex;
}

.nowrap {
  white-space: nowrap;
}
.header-search-form-properties {
  position: absolute;
  background: $colorBackgroundLightest;
  padding: 10px;
  box-sizing: content-box;
  margin-left: -10px;
  box-shadow: 0 3px 7px 0 $colorBackgroundShade1;
  border-radius: 5px;
  z-index: 2;
  @media (max-height: $mobile-iframe-height) and (max-width: $desktop-iframe - 1) {
    margin-top: -250px;
  }
  @media (max-height: $desktop-iframe-height) and (max-width: $desktop-iframe) {
    margin-top: -120px;
  }
}

.header-search-form-properties-hidden {
  display: none;
}

.header-search-form-properties-table {
  z-index: 2;
  width: 334px;
}

.header-search-form-properties-table td {
  border: none !important;
}

.header-search-form-properties-class-name {
  float: left;
  margin: 10px;
}

.header-search-form-properties-class-select {
  width: 60%;
}

.header-search-form-properties-row {
  text-align: left;
  td:first-of-type {
    width: 50%;
  }
}

.max-passengers-error {
  width: 200%;
  margin-top: 2px;
  margin-bottom: 6px;
  text-align: left;
  color: $colorTextWarning;
}

.header-search-form-mobile-send-button {
  text-align: center;
}

.header-search-form-additional-bottom-tolerance-wrapper {
  // border: 1px solid $color-text-warning;
  display: flex;
  clear: both;
  user-select: none;
}

.header-search-form-additional-tolerance {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $colorBorderSearchFormAdditional;
  height: 60px;
  font-size: 14px;
}

.header-search-form-additional-bottom-tolerance {
  height: 40px;
  width: 94px;
  border-radius: 3px;
  background-color: $colorBackgroundLightest;
  box-shadow: 0 2px 4px 0 $colorBackgroundShade1;
  box-sizing: border-box;
  padding-top: 10px;
  text-align: center;
  position: relative;
  float: left;
  user-select: none;
  margin-left: 20px;
}

.header-search-form-additional-bottom-tolerance-buttons {
  display: flex;
  flex-direction: row;
  height: 40px;
  margin-left: 15px;
  width: auto;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  background-color: $colorBackgroundLightest;
  box-shadow: 0 2px 4px 0 $colorBackgroundShade1;
  .tolerance-item {
    position: relative;
  }
  .plus-button {
    left: 12px;
    top: 2px;
    img {
      padding: 0;
    }
  }
  .minus-button {
    right: 12px;
    top: 2px;
    img {
      padding: 0;
    }
  }
}

.header-search-form-additional-bottom-tolerance-disabled {
  background: #{$colorBackgroundDarkest}0c;
}

.number-stepper {
  display: flex;
  align-items: center;
  padding-right: 8px;
}

.number-stepper__number {
  margin: 0 10px;
  font-weight: bold;
}

.number-stepper__action-btn {
  display: flex;
  justify-content: center;
  height: 24px;
  width: 24px;
  background: linear-gradient(
    150.45deg,
    $colorMainButtonGradientStart 0%,
    $colorMainButtonGradientEnd 100%
  );
  border-radius: 50%;
  color: $colorTextLightest;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.number-stepper__action-btn--disabled {
  cursor: not-allowed;
}

.header-search-form-additional-bottom-tolerance-minus {
  position: absolute;
  height: 24px;
  width: 24px;
  background: linear-gradient(
    150.45deg,
    $colorMainButtonGradientStart 0%,
    $colorMainButtonGradientEnd 100%
  );
  border-radius: 12px;
  color: $colorTextLightest;
  font-size: 16px;
  left: -12px;
  top: 8px;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.header-search-form-additional-bottom-tolerance-plus {
  position: absolute;
  height: 24px;
  width: 24px;
  background: linear-gradient(
    150.45deg,
    $colorMainButtonGradientStart 0%,
    $colorMainButtonGradientEnd 100%
  );
  border-radius: 12px;
  color: $colorTextLightest;
  font-size: 16px;
  right: -12px;
  top: 8px;
  user-select: none;
  cursor: pointer;
}

.header-search-form-additional-bottom-tolerance--disabled {
  cursor: not-allowed;
}

.header-search-form-additional-bottom-weekends {
  display: flex;
  align-items: center;
  width: 75%;
  padding-bottom: 10px;
  margin-top: 5px;
  @media (min-width: $desktop) {
    margin-top: 0px;
  }
}

.header-search-form-additional-bottom-centered {
  display: flex;
}

.header-search-form-additional-bottom-weekends-label {
  font-size: 14px;
  user-select: none;
}

.header-search-form-multiple-add {
  width: 85px;
  margin-top: 17px;
  cursor: pointer;
  user-select: none;
  display: flex;
  @media (min-width: $desktop) {
    display: block;
  }
}

.header-search-form-multiple-add-inner {
  padding-bottom: 30px;
  min-width: 110px;
  @media (min-width: $desktop) {
    padding-bottom: 0px;
    height: 35px;
  }
}

.header-search-form-multiple-add-inner-image {
  width: 24px;
  padding: 0px !important;
  margin: 0px;
}

@mixin container-styles {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  min-width: 105px;
  margin-bottom: 25px;
}

.header-search-form-multiple-add-inner-container-plus {
  @include container-styles;
}

.header-search-form-multiple-add-inner-container-minus {
  @include container-styles;
  @media (min-width: $desktop) {
    margin-bottom: 12px;
  }
}

@mixin button-label-styles($color, $text-decoration) {
  color: $color;
  text-align: center;
  font-size: 14px;
  text-decoration: $text-decoration;
  display: flex;
  align-items: center;
  padding-left: 10px;
  text-align: left;
}

.header-search-form-multiple-add-plus-button-label {
  @include button-label-styles($colorTextLink, underline);
}

.header-search-form-multiple-add-minus-button-label {
  @include button-label-styles($colorTextAdditionalLight, underline);
}

.header-search-form-multiple-add-plus {
  // position: absolute;
  height: 24px;
  width: 24px;
  background: linear-gradient(
    150.45deg,
    $colorMainButtonGradientStart 0%,
    $colorMainButtonGradientEnd 100%
  );
  border-radius: 12px;
  color: $colorTextLightest;
  font-size: 18px;
  right: -12px;
  top: 8px;
  cursor: pointer;
  padding-left: 6px;
  box-sizing: border-box;
  float: left;
}

.header-search-form-multiple-add-plus-label {
  color: $colorTextLink;
  font-size: 14px;
  text-decoration: underline;
  // font-weight: bold;
  position: relative;
  // top: 1px;
  left: 10px;
}

.header-search-form-multiple-add-minus {
  // position: absolute;
  height: 24px;
  width: 24px;
  background: $colorBackgroundAdditionalLight; //linear-gradient(150.45deg, $color-main-button-gradient-start 0%, $color-main-button-gradient-end 100%);
  border-radius: 12px;
  color: $colorTextLightest;
  font-size: 18px;
  right: -12px;
  top: 8px;
  cursor: pointer;
  padding-left: 8px;
  box-sizing: border-box;
  float: left;
}

.header-search-form-multiple-add-minus-label {
  color: $colorTextAdditionalLight;
  font-size: 14px;
  text-decoration: underline;
  position: relative;
  left: 10px;
}

.date-picker-wrapper {
  user-select: none;
  cursor: pointer;
}

#header-search-form-div_toggle_properties {
  cursor: pointer;
  user-select: none;
}

.header-search-form-calendar-wrapper {
  position: fixed;
  // opacity: 0.8;
  background-color: #{$colorBackgroundAdditionalDark}CC;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  overflow: scroll;
}

.header-search-form-calendar {
  border-radius: 4px;
  background-color: $colorBackgroundLightest;
  margin: 12px;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  min-height: 475px;

  @media (min-width: $desktop) {
    width: 785px;
    margin: 0 auto;
  }
}

.header-search-form-calendar-top-close {
  text-align: right;
}

.header-search-form-calendar-top-close-ico {
  cursor: pointer;
}

.header-search-form-calendar-description {
  font-size: 14px;
}

.header-search-form-calendar-title {
  height: 35px;
  border: 1px solid $colorBorderBackgroundShade1;
  border-radius: 17.5px;
  background: $colorBackground;
  margin: 25px 0px 15px 0px;
  box-sizing: border-box;
  padding-top: 9px;
  text-transform: uppercase;
  font-size: 12px;
  @media (min-width: $desktop) {
    width: 60%;
  }
}

.header-search-form-calendar-months {
  @media (min-width: $desktop) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.header-search-form-web .header-search-form-desktop-line {
  @media (min-width: $desktop) {
    display: flex;
    .preferences {
      width: 33%;
    }
  }
}

.header-search-form-desktop-line-one {
  @media (min-width: $desktop) {
    width: 32%;
  }
}

.container[max-width~="960px"] .header-search-form-desktop-line-one {
  width: 100%;
}

.header-search-form-multiple-preferences {
  padding-bottom: 25px;
  @media (min-width: $desktop) {
    width: 25%;
  }
  margin-bottom: 0;
}

.container[max-width~="960px"] .header-search-form-multiple-preferences {
  margin-top: 25px;
  width: 100%;
}

.header-search-form-multiple-cbt-passengers {
  padding-bottom: 25px;
  margin-bottom: 0;
  @media (min-width: $desktop) {
    width: 61%;
  }
}

.header-search-form-multiple-divider {
  height: 1px;
  width: 100%;
  background-color: $colorBackgroundShade1;
  margin-bottom: 30px;
}

.header-search-form-calendar-month {
  display: flex;
}

.header-search-form-calendar-month-title-link {
  height: 40px;
  width: 40px;
  border: 1px solid $colorBorderBackgroundShade1;
  border-radius: 20px;
  background-color: $colorBackground;
  padding-top: 7px;
  box-sizing: border-box;
  text-align: center;
  position: relative;

  &.previous {
    float: left;
  }

  &.next {
    float: right;
  }
}

.header-search-form-calendar-month-title-link-previous {
  transform: rotate(90deg);
}

.header-search-form-calendar-month-title-link-next {
  transform: rotate(270deg);
}

.header-search-form-calendar-month-title-text {
  flex-grow: 1;
  text-align: center !important;
  padding-top: 10px;
}

.loader-wrapper-mini {
  position: fixed;
  // opacity: 0.8;
  background-color: #{$colorBackgroundAdditionalDark}40;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.loader-wrapper {
  position: fixed;
  // opacity: 0.8;
  background-color: transparent;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.no-background-image {
  background-color: #{$colorBackgroundAdditionalDark}99;
}

.loader-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  object-position: center center;
}

.loader {
  z-index: 3;
  border-radius: 4px;
  background-color: $colorBackgroundLightest;
  margin: 50% 12px 12px 12px;
  padding: 20px 20px 40px 20px;
  box-sizing: border-box;
  text-align: center;
  @media (min-width: $desktop) {
    width: 730px;
    top: 30%;
    margin: auto;
    position: relative;
  }
  animation-duration: 300ms;
}

.h3-loader {
  color: $colorTextProgressBar;
  font-size: 18px;
  margin-top: 0;
}

.loader-line {
  .rc-progress-line-path {
    stroke: $colorBackgroundProgressBarDark;
  }

  .rc-progress-line-trail {
    stroke: $colorBackgroundProgressBarLight;
  }
}

.loader-top-image {
  position: relative;
  top: -60px;
  width: 110px;
  height: 110px;
  border-radius: 55px;
  background: $colorBackgroundLightest;
  text-align: center;
  padding-left: 10px;
  padding-top: 25px;
  box-sizing: border-box;
  left: 50%;
  margin-left: -55px;
  margin-bottom: -20px;
}

.loader-placeholder {
  color: $colorTextAdditionalLight;
  font-size: 13px;
  clear: both;
}

.header-menu-line {
  display: none;
  border-radius: 5px;
  background-color: $colorBackgroundLightest;
  box-shadow: 0 3px 7px 0 $colorBackgroundShade1;
  margin: 40px 0 20px 0;
  align-items: center;
  animation: fadeIn 1s ease;

  @media (min-width: $desktop) {
    display: flex;
    padding: 21px;
  }
}

.header-menu-line-empty {
  height: 25px;
  @media (min-width: $desktop) {
    height: 45px;
  }
}

.header-menu-line a {
  flex-grow: 1;
  border-right: 1px solid $colorBorderBackgroundShade1;
  padding-left: 20px;
  padding-right: 10px;
  font-size: 14px;
  color: $colorText;
  text-decoration: none;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
}

.header-menu-line a:last-of-type {
  border: none;
}

//Select own
.Select-option.is-selected {
  /* // background-color: blue !important; */
  background-color: #{$colorBackgroundDarkest}0a;
}

// .Select-option
.is-focused {
  // .Select-option:hover {
  /* //tmavsi */
  /* // background-color: $color-text-warning ; */
  background-color: #{$colorBackgroundDarkest}0a;
}

// old working modal links
.modal-submit-row {
  display: flex;
  margin-left: 0;
  margin-top: 0;
  justify-content: space-between;
  align-items: center;
  @media (min-width: $desktop) {
    margin-left: 159px;
  }
}

.modal-submit-login-button {
  margin: 0;
}

.modal-submit-row-links {
  flex-grow: 1;
  margin-left: 20px;
  font-size: 14px;
}

.ReactModalPortal > div {
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  transition: opacity 200ms ease-in-out;
  background: #{$colorBackgroundDarkest}26;

  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
  }
}

.ReactModal__Body--open {
  @media (max-width: $desktop) {
    overflow: hidden;
  }
}

.noScroll {
  overflow: hidden;
}

.header-reservations-link {
  margin-top: 17px;
  margin-left: 15px;
  display: inline-block;
}

.header-search-form-link-text {
  color: $colorTextLink;
  text-decoration: underline;
  cursor: pointer;
  font-size: 9pt;

  &:hover {
    color: $colorMainButtonGradientEnd;
  }
}

.header-search-form-link-divider {
  margin: 0px 10px;
}

.information-tooltip-content {
  margin: 5px 15px 10px 15px;
  width: 250px;
}

.information-tooltip-circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 25px;
  font-weight: bold;
  text-align: center;
  background: $colorBackgroundShade2;
  cursor: pointer;
}

.no-padding {
  padding: 0 !important;
}

.information-tooltip {
  margin-left: 10px;
}

.react-select-2-wrapper {
  position: relative;
}

.react-select-2-placeholder {
  color: $colorTextAdditionalDark !important;
  margin-left: 0px !important;
  font-size: 17px;
  padding-left: 1px;
}

.react-select-2-wrapper-2 {
  padding-top: 2px !important;
}

.header-search-form-desktop-line {
  min-height: 48px !important;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: $desktop) {
    flex-direction: column;
  }

  &.iframe {
    @media (min-width: $desktop-iframe) {
      display: flex;
    }
  }

  &.header-search-form-desktop-line--half {
    flex-direction: row;
  }
}

.container[max-width~="1009px"] {
  .header-search-form-desktop-line {
    display: flex;
    flex-direction: column;
  }
}

.container[max-width~="1009px"] {
  .header-search-form-desktop-line--half {
    flex-direction: row;
  }
}

.simple-select-beforemount {
  opacity: 0;
}

.simple-select-aftermount-appear {
  opacity: 1;
  transition: all 0.9s ease-out;
  transition-delay: 0.3s;
}

.search-field-error {
  color: $colorTextWarning;
  border-top: 1px solid $colorBorderTextWarning;
  margin-right: 25px;
  position: absolute;
  height: 0;
  font-size: 12px;
  width: 60%;
  animation: fadeIn 0.5s ease;
  @media (min-width: $desktop) {
    width: 250px;
  }
}

.passengers-options-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $colorBackgroundLightest;
  box-shadow: 0 3px 7px $colorBackgroundShade1;
  border-radius: 4px;
  z-index: 99;
  padding: 20px 20px 30px 20px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  width: calc(100vw - 40px);

  @media (min-width: $desktop) {
    width: 650px;
    right: 0;
  }
}

.passengers-options-dialog__row {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
}

.passengers-options-dialog__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.passengers-options-dialog__add-person-btn {
  font-family: Muli, Arial, sans-serif !important;
  font-size: 13px;
  margin-right: 2px;
  border: none;
  outline: none;
  background-color: transparent;
  color: $colorTextAdditionalDark;
}

.passengers-options-dialog__row--borders {
  border-top: 1px solid $colorBorderBackground;
  border-bottom: 1px solid $colorBorderBackground;
}

.passengers-options-dialog__confirm {
  padding: 14px 46px;
  border-radius: 4px;
  font-weight: bold;
  color: $colorTextLightest;
}

.passengers-options-dialog__confirm-row {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: right;
}

.passengers-options-dialog__error-msg {
  color: $colorTextWarning;
  padding-left: 0px !important;
}

.travelersSelect {
  box-sizing: border-box;
  width: 220px;
  position: absolute;
  top: 20px;
  right: 0;
  background-color: #fff;
  z-index: 2;
  box-shadow: 0px 3px 6px #{$colorBackgroundDarkest}0d;
  border-radius: 4px;

  &.travelersSelect__travelReason {
    width: 235px;
  }

  .travelersSelect__input-wrap {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .travelersSelect__input {
    font-size: 16px;
    box-sizing: border-box;
    width: 100%;
    height: 44px;
    padding: 0 8px;
    outline: none;
    border: 1px solid $colorBorderText;
    border-radius: 2px;
  }

  .travelersSelect__options {
    padding: 0;
    list-style: none;
    margin: 0;
    max-height: 300px;
    overflow: auto;
    border-bottom: 1px solid $colorBorderAdditionalLight2;
  }

  .travelersSelect__option {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 8px 12px;
    cursor: pointer;
    border: none;
    width: 100%;
    background-color: transparent;
    text-align: left;
    outline: none;
    font-size: 16px;
    line-height: 20px;
    color: $colorTextDarkest;

    &.travelersSelect__option--hover {
      background-color: $colorBackgroundShade1;
    }
  }

  .travelersSelect__stop-btn {
    display: flex;
    align-items: center;
    font-size: 16px;
    width: 100%;
    background: transparent;
    color: $colorYellow01;
    height: 38px;
    padding: 10px 12px;
    border: 0;
    text-decoration: underline;

    &:hover {
      background-color: $colorBackgroundShade1;
      cursor: pointer;
    }
  }
}

.passengers-options-dialog__select {
  min-width: 140px;
}

.passengers-options-dialog__selected-value {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: $colorText;
}

.passengers-options-dialog__travelers {
  display: flex;
  flex-wrap: wrap;
}

.passengers-options-dialog__traveler {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  padding: 10px;
  background-color: $colorBackground;
  border-radius: 4px;
  margin-right: 6px;
  margin-bottom: 6px;
}

.passengers-options-dialog__travelers-remove-btn {
  width: 16px;
  height: 16px;
  margin-left: 6px;
  font-size: 0;
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
  background-image: url(/static/images/cross-gray.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.header-search-form__cbt-passengers {
  font-size: 13px;
  color: $colorText;
}

.header-search-form__cbt-passengers-main-info {
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 4px;
}

.header-search-form__cbt-passengers-additional-info {
  margin-bottom: 4px;
}

.minimized-header-search-form-locations-separator {
  margin: 0 5px 2px;
}

.minimized-header-search-form-dates-separator {
  margin: 0 5px 6px;
}

.customSelect {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  border-radius: 4px;

  .customSelect__input-wrap {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .customSelect__input {
    cursor: pointer;
    outline: none;
  }

  .customSelect__input-arrow {
    position: absolute;
    right: 8px;
  }

  .customSelect__input-arrow--up {
    transform: rotate(180deg);
  }

  .customSelect__options {
    position: absolute;
    background-color: $colorBackgroundLightest;
    box-shadow: 0 0 7px 1px #{$colorBackgroundAdditionalLight}4c;
    padding: 0 0 10px;
    list-style: none;
    margin: 0 0 4px;
    width: 100%;
    max-height: 300px;
    overflow: auto;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 1;
    animation: fadeIn 0.2s ease;
  }

  .customSelect__options--top {
    bottom: 42px;
  }

  .customSelect__option {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 38px;
    padding: 0 8px;
    cursor: pointer;
    border: none;
    width: 100%;
    background-color: transparent;
    text-align: left;
    outline: none;
    font-size: 16px;
    color: $colorTextDarkest;
    &.customSelect__option--hover {
      background-color: $colorBackgroundShade1;
    }
  }
}

.dateInput {
  position: relative;
  .dateInput__calendarWrapper {
    position: absolute;
    background-color: $colorBackgroundLightest;
    z-index: 1;
    box-shadow: 0px 3px 6px #{$colorBackgroundDarkest}0d;
    border-radius: 4px;
  }

  .DayPicker-wrapper {
    padding: 0;
  }

  .DayPicker-Months .DayPicker-Month {
    margin: 10px !important;
  }

  &.showTop .DayPickerInput-Overlay {
    bottom: 45px;
  }
}

body[min-width~="960px"] .DayPicker-htmlPackage-custom-height {
  height: 345px;
}
