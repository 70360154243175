.main-wrapper > h1 {
  font-size: 28px;
  line-height: 36px;
}
.main-wrapper > h3 {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.18px;
  line-height: 23px;
}
