.branded-fares-modal {
  .modal-inner {
    max-height: 90%;
    max-width: 90%;
  }

  .modal-content {
    overflow-y: auto;
  }

  &.show-vertical-scrollbar {
    .modal-inner {
      overflow-y: scroll;
    }

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: rgba(0, 0, 0, 0.25);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }

  &.show-horizontal-scrollbar {
    .modal-content {
      overflow-x: scroll;
    }
  }
}

.branded-fares {
  &.-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  &.-error {
    color: $colorTextWarning;
  }
}

.brands-table {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  color: $colorTextDarkest;

  .table-header-cell,
  .table-cell,
  .table-header-cell-new {
    font-size: 15px;
    text-align: center;
  }

  .table-header-cell,
  .table-header-cell-new {
    background-color: $colorBackgroundGrayLight;
    font-weight: 700;
    padding: 0px !important;
  }

  .table-column {
    display: flex;
    flex-flow: column;

    &.-options {
      min-width: fit-content;
    }

    &:first-child {
      .table-header-cell {
        justify-content: flex-start;
      }
    }
  }

  .table-footer-cell {
    text-align: center;
  }
}

.table-footer-center-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-header-row {
  background-color: $colorBackgroundGrayLight;
  font-weight: 700;
}

.table-header-text {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 7px;
  margin-right: 7px;
}

.brands-table-service-name-text {
  display: inline-block;
  border-bottom: none;
  cursor: default;
}

.brands-table-service-name-text-with-tooltip {
  cursor: pointer;
  display: inline-block;
  text-align: left;
  border-bottom: 1px dotted $color-dark-gray-05;
}

.brands-table-travel-policy-icon {
  margin-top: 3px;
}

.brands-table-service-symbol {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brands-table-service-name-tooltip,
.brands-table-service-symbol-tooltip {
  .rc-tooltip-inner {
    max-width: 400px;
  }
}

.brands-table-service-name-center {
  padding-top: 5px;
  margin-left: 5px;
}

.brands-table-service-name,
.brands-table-service,
.table-header-cell,
.table-header-cell-new {
  border-bottom: 1px solid $colorBackgroundShade1;
  box-sizing: border-box;
  text-align: center;
  min-width: 150px;
  max-width: 250px;

  &-first {
    @extend .table-header-cell-new;
    text-align: left !important;
  }

  &-center {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    padding-bottom: 5px;
    @extend .brands-table-service-name;
    border-bottom: 1px solid $colorBackgroundShade1;
  }
}

.table-header-cell {
  height: 80px !important;
}

.brands-service-price {
  font-size: 19px;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
  padding: 10px 0px 0px 0px;
}

.brands-table-select-btn {
  background-color: $colorTextLink;
  padding: 14px 28px;
  font-size: 13px;
  border-radius: 4px;
  margin: 10px 0;
  border: 0;
  font-weight: 800;
  line-height: 16px;
  text-transform: uppercase;
  cursor: pointer;
  color: $colorTextWhite;

  &:hover {
    background-color: darken($colorTextLink, 5%);
  }
}

.brands-table-travel-policy {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #808080;
  margin: 8px 0;
  cursor: pointer;
  text-decoration: underline;
}

.brands-table-travel-policy--passed {
  color: #e85e21;
}

.price-tag {
  width: 26px;
  height: 24px;
  background-color: $colorTextLink;
  -webkit-mask-image: url(/static/images/ico-price-tag.svg);
  mask-image: url(/static/images/ico-price-tag.svg);
  -webkit-mask-position: top;
  mask-position: top;
}
