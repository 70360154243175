.modal-root {
  &.-active {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.modal-bg {
  background-color: rgba($colorBackgroundAdditionalDark, 0.75);
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-title {
  color: $colorTextDarkest;
  margin-top: 0;
  font-size: 28px;
  margin-right: 100px;
}

.modal-inner {
  max-height: 90%;
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  position: relative;
  overflow-y: auto;
  animation: fadeInOpacity both cubic-bezier(0.4, 0, 0, 1.5) 300ms;
}

@keyframes fadeInOpacity {
  0% {
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.modal-close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  border: 0;
  background: transparent;
  cursor: pointer;

  &:hover,
  &:focus {
    font-weight: 700;
    outline: none;

    .icon-color--dark-gray > svg {
      fill: $color-darkest-03;
    }
  }
}
