.reservation-overview-information {
  display: inline-block;
  margin-right: 29px;
  margin-top: 20px;
  font-size: 13px;
}

.trip-info-line {
  display: flex;
  font-size: 14px;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0px;
  }
}

.trip-info-label {
  width: 150px;
  margin-right: 20px;
}

.trip-info-value {
  flex: 1;
  font-weight: bold;
}

.passengers {
  margin: -10px 0;
}

.passenger-line {
  display: flex;
  border-bottom: 1px solid $colorBorderAdditionalLight2;
  height: 42px;
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
}

.passenger-index {
  width: 22px;
  font-size: 14px;
}

.passenger-name {
  font-size: 14px;
  font-weight: bold;
  width: auto;
}

.passenger-additional {
  font-size: 13px;
  color: $colorTextAdditionalDark;
  flex: 1;
}

.payment-method-line {
  margin-top: 20px;
}

.reservation-status-miniloader {
  position: absolute !important;
  top: 15px !important;
}

.reservation-detail-back-button-wrapper {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 700px) {
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
}

/*
  Hotel detail
*/
.reservation-detail-hotel-info {
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
}

.reservation-detail-hotel-title {
  font-size: 14px;
  font-weight: bold;
  background: $colorBackground;
  padding: 8px 16px;
  border-radius: 8px;

  .reservation-detail-hotel-title__icon {
    margin-right: 8px;
    height: 11px;
  }
}

.reservation-detail-hotel-info-content {
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  @media (min-width: 700px) {
    flex-direction: row;
  }
}

.reservation-detail-hotel-info-content-data {
  display: flex;
  flex-direction: column;
  margin: 8px 0 8px 0;

  @media (min-width: 700px) {
    margin: 0 0 8px 16px;
  }
}

.reservation-detail-hotel-info-content-dataitem {
  display: flex;
  flex-direction: column;
  font-size: 13px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.reservation-detail-hotel-info-content-dataitem_label {
  margin-right: 8px;
  opacity: 0.7;

  &::after {
    content: ":";
  }
}

.reservation-detail-hotel-info-content-dataitem_value {
  font-weight: bold;
  font-size: 14px;
}

.reservation-detail-hote-info-map {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  position: relative;
  margin-bottom: 10px;
}
