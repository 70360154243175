.register-select-wrapper {
  width: 100%;

  @media (min-width: $desktop) {
    display: inline-block;
    width: 400px;
  }
}

.register-error-message {
  display: block;
  color: $colorTextWarning;
}

.create-new-password-error-message {
  display: block;
  color: $colorTextWarning;
  margin-top: 15px;
}

.register-telephone-wrapper .custom-input-wrapper-error {
  margin-top: 28px;
  @media (min-width: $desktop) {
    margin-top: inherit;
  }
}

// login page
.login-form {
  margin-left: 20px;
  margin-right: 20px;
  max-width: 670px;
  margin-bottom: 40px;

  .header-search-form-tabs {
    display: flex;

    align-items: center;

    @media (min-width: 670px) {
      display: block;
    }
  }

  .header-search-form-tab {
    width: inherit;
    flex: 1;
    padding-left: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;

    @media (min-width: 670px) {
      display: inline-block;
      padding: 17px 15px 0 15px;
    }
  }

  .header-search-form-tab-text {
    margin: 0;
  }

  .input-input {
    max-width: 320px;
  }

  @media (min-width: 670px) {
    margin: 0 auto;
    margin-bottom: 40px;
  }

  .input-wrapper {
    margin-bottom: 15px;
    @media (min-width: 670px) {
      display: flex;
      margin-left: 0px;
    }
  }
  .input-wrapper .input-label {
    @media (min-width: 670px) {
      width: 28%;
      position: relative;
      top: 14px;
    }
  }
}

.login-register-link {
  text-decoration: none;
}

.login-form-inner {
  background: $colorBackgroundLightest;
  border-radius: 0px 5px 0 0;
  padding: 30px;
  box-sizing: border-box;

  @media (min-width: 670px) {
    padding: 50px;
  }
}

.login-submit-row {
  margin-top: 5px;
}

.login-submit-button {
  font-size: 13px;
  font-weight: bold;
  text-transform: none;
}

.login-link-forgotten-password {
  margin-left: 20px;
  font-size: 13px;
  font-weight: bold;
}

.login-partners {
  margin-top: 43px;
  padding-top: 27px;
  border-top: 1px solid $colorBorderAdditionalLight2;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.login-partners img {
  flex-shrink: 1;
  max-height: 55px;
  margin: 0 20px;
  width: 70px;

  @media (min-width: 670px) {
    width: inherit;
  }
}

.login-error-warning {
  width: 100%;
  max-width: 320px;

  @media (min-width: 670px) {
    min-width: 320px;
  }
}

.login-title {
  margin: 0;
}

.login-description {
  margin: 10px 0 34px 0;
}
