.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.icon-full-size {
  > svg,
  img {
    width: 100%;
    height: 100%;
  }
}

.icon-size,
.icon-background-size {
  &--sm {
    width: 10px;
    height: 10px;
  }

  &--md {
    width: 20px;
    height: 20px;
  }
}

.icon-color {
  &--white {
    color: #fff;
  }
  &--dark-gray {
    color: $color-dark-gray-05;
  }
  &--dark-orange {
    color: $colorTextProgressBar;
  }
}

.icon-type {
  &--cross {
    &.icon-color {
      &--dark-gray {
        > svg {
          fill: $color-dark-gray-05;
        }
      }
    }
  }

  &--price-tag {
    &.icon-color {
      &--yellow {
        > svg .a,
        .b {
          fill: $colorTextLink;
        }
      }
    }
  }
}

.icon-background {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-background-type {
  &--rounded {
    border-radius: 100%;
  }
}

.icon-background-color {
  &--yellow {
    background-color: $colorTextLink;
  }
}
