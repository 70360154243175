.layout--error {
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}
.error-page {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;

  .universal-content-box {
    max-width: 1010px;
    margin: 0 5px;
    text-align: center;
    padding: 25px 20px 80px 20px;

    @media (min-width: $desktop) {
      margin: 10px auto;
    }
  }

  .static-image {
    max-width: 100%;
    @media (min-width: $desktop) {
      max-width: 1010px;
    }
  }

  .primary-button {
    color: white;
    text-decoration: none;

    & > a {
      text-decoration: none;
    }
  }
}

.error-page-h {
  display: block;
  font-size: 20px;
  margin-top: 15px;
  color: $colorTextDanger;
}

.error-page-description {
  margin: 30px 35px;
  font-size: 14px;
  display: block;
}
