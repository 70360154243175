$desktopAditionalModal: 1100px;

.result-filter-btn-container {
  z-index: 10;
  left: 12px;
  transition: left 0.8s ease-in-out;
  @media (min-width: $desktop) {
    display: none;
  }
}

.result-filter-btn-container.hidden {
  display: none;
}

.result-filter-icon-container,
.result-filter-icon-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.result-filter-icon-container {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  background-color: $colorBackgroundLightest;
  cursor: pointer;
}

.search-steps {
  display: flex;
  align-items: center;
  user-select: none;
  background-color: $colorBackground !important;
  margin: 20px 0px;
  padding: 0px 10px;
  border-radius: 5px;
  @media (min-width: $desktop) {
    margin-top: 0px;
    background: transparent;
  }
}

.search-steps-mobile {
  box-sizing: border-box;
  background: $colorBackgroundLightest;
  display: flex;
  margin-top: 20px;
  padding: 10px 20px;
  @media (min-width: $desktop) {
    display: none;
  }
}

.search-steps-mobile-wrapper {
  padding: 0px 5px;
}

.search-steps-mobile-menu-row {
  padding: 4px 20px;
  background: $colorBackgroundLightest;
}

.search-steps-mobile-menu-row-inactive {
  background: $colorBackgroundLightest;
}

.search-steps-desktop {
  display: none;
  @media (min-width: $desktop) {
    display: block;
    flex: auto;
  }
}

.search-steps-circle {
  box-sizing: border-box;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: inline-block;
  padding: 10px;
  text-align: center;
  margin-right: 15px;
  font-weight: bold;
  border: 1px solid $colorBorderText;
}

.search-steps-circle-active {
  @extend .search-steps-circle;
  border: 1px solid $colorBorderLink;
  color: $colorTextLink;
}

.search-steps-circle-finished {
  @extend .search-steps-circle;
}

.search-steps-mobile-circle {
  @extend .search-steps-circle;
  border: 1px solid $colorBorderText;
  margin-top: 5px;
}

.search-steps-mobile-circle-active {
  @extend .search-steps-mobile-circle;
  color: $colorTextLink;
  border: 1px solid $colorBorderLink;
}

.search-steps-mobile-circle-finished {
  @extend .search-steps-mobile-circle;
}

.search-steps-label {
  flex-grow: 1;
  font-size: 14px;
  position: relative;
  top: 12px;
  user-select: none;
  @media (min-width: $desktop) {
    margin-right: 16px;
    top: 0;
  }
}

.search-steps-label-active {
  @extend .search-steps-label;
  color: $colorTextLink;
  font-weight: bold;
}

.search-steps-label-finished {
  @extend .search-steps-label;
  font-weight: bold;
}

.search-steps-mobile-label {
  @extend .search-steps-label;
  top: 0;
}

.search-steps-mobile-label-active {
  @extend .search-steps-mobile-label;
  color: $colorTextLink;
}

.search-steps-mobile-label-finished {
  @extend .search-steps-mobile-label;
}

.select-arrow-rotate-up {
  transform: rotate(180deg);
}

.closed-rule-arrow {
  margin-right: 8px;
  transform: rotate(-90deg);
}

.opened-rule-arrow {
  margin-right: 8px;
  transform: rotate(0deg);
}

.search-steps-divider {
  display: inline-block;
  width: 5px;
  background-color: $colorNavigationLine;
  height: 1px;
  position: relative;
  top: -3px;
  margin-right: 16px;
}

.flight-offer-brands {
  display: flex;
  align-items: center;
}

.flight-offer-brand {
  border-radius: 4px 4px 0 0;
  background-color: $colorBackgroundShade1;
  padding: 15px 20px;
  font-weight: bold;
  margin-right: 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 10px;

  &:hover,
  &.active {
    background-color: $colorBackgroundLightest;
  }

  img {
    height: 25px;
    margin: 0 1px;
  }

  .flight-offer-brand-nameicon-wrapper {
    display: flex;
    align-items: center;
    height: 25px;
  }

  .flight-offer-brand-icons {
    margin-right: 4px;
    margin-top: 4px;
  }

  @media (max-width: $desktop) {
    font-size: 15px;
    padding: 15px 10px;
    margin-right: 6px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.more-brands-link {
  border: 0;
  background: transparent;
  cursor: pointer;
  font-size: 13px;
  color: $colorTextLink;
  flex-shrink: 2;

  @media (max-width: $desktop) {
    margin-left: 5px;
  }

  &:focus {
    font-weight: 700;
    outline: none;
  }
}

.flights {
  margin-top: -5px;
  @media (min-width: $desktop) {
    max-width: 725px;
  }
}

.flight {
  background-color: $colorBackgroundLightest;
  @media (min-width: $desktop) {
    display: flex;
    align-items: center;
  }
}

.flight-segments {
  padding-top: 20px;
  @media (min-width: $desktop) {
    flex-grow: 1;
    align-self: flex-start; // good for one-way flight
    padding: 20px 10px 0;
    border-right: 2px dashed $colorBorderBackground;
  }
}

.flight-segments-best-offer {
  display: none;
  align-items: center;
  justify-content: center;
  float: right;
  height: 30px;
  width: 140px;
  border-radius: 5px 0 0 5px;
  background-color: $colorBackgroundBestOffer;
  line-height: 16px;
  vertical-align: middle;
  text-align: center;
  color: $colorTextLightest;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.09px;
  @media (min-width: $desktop) {
    display: flex;
  }
}

.flight-segments-best-offer-mobile {
  @extend .flight-segments-best-offer;
  margin-top: 15px;
  display: flex;
  @media (min-width: $desktop) {
    display: none;
  }
}

.flight-additional-info {
  position: relative;
  user-select: none;
  border-radius: 0 0 10px 10px;
  background-color: $colorBackgroundLightest;
  display: flex;
  padding: 15px 20px 15px 20px;
  box-sizing: border-box;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 3px;
  flex-wrap: wrap;
  @media (min-width: $desktop) {
    display: none;
    flex-wrap: nowrap;
  }
}

.flight-additional-info-mobile-links {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-bottom: 15px;
  width: 100%;
}

.flight-additional-info-desktop {
  user-select: none;
  display: none;
  height: 55px;
  @media (min-width: $desktop) {
    display: flex;
    align-items: center;
  }

  &-invalid {
    display: flex;
    align-items: center;
  }
}

.flight-additional-info-desktop-basics {
  display: flex;
  justify-content: space-between;
  @media (min-width: $desktop) {
    width: 220px;
  }
}

.flight-additional-info-desktop-information {
  flex-grow: 2;
  justify-content: space-between;
  text-align: center;
  font-size: 13px;
  color: $colorTextLink;
  cursor: pointer;
  user-select: none;
}

.flight-additional-info-desktop-print {
  display: inline-flex;
  justify-content: space-between;
  opacity: 0;
}

.text-separator {
  color: $colorTextAdditionalLight;
  margin: 0 4px;
}

.flight-additional-info-icon {
  display: flex;
  align-items: center;
  padding: 0 5px 0 5px;
}

.flight-additional-info-icon-icon {
  height: 30px;
  margin-right: 2px;
}

.flight-additional-info-icon-number {
  font-size: 14px;
  color: $colorTextSuccess;
}

.flight-additional-info-icon-number-not-allowed {
  @extend .flight-additional-info-icon-number;
  color: red;
}

.flight-additional-info-baggage-modal-content {
  display: flex;
  flex-direction: column;
  margin: 24px 8px;
  @media (min-width: $desktopAditionalModal) {
    margin: 40px;
  }
}

.flight-additional-info-baggage-modal-header {
  white-space: "pre-line";
}

.flight-additional-info-baggage-modal-table {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
}

.flight-additional-info-baggage-modal-header-wrap {
  display: flex;
  flex-direction: column;
  margin: 8px 16px 0 16px;

  @media (min-width: 400px) {
    flex-direction: row;
    align-items: center;

    .switch {
      margin-left: 16px;
    }
  }
  @media (min-width: $desktopAditionalModal) {
    justify-content: space-between;
  }
}

.flight-additional-info-baggage-modal-table-header {
  display: flex;
  align-items: center;
  background: #e3edf3;
  @media (max-width: $desktopAditionalModal - 1) {
    display: none;
  }
}

.flight-additional-info-baggage-modal-infantheader {
  text-align: center;
  font-weight: bold;
  padding-left: 16px;
  margin: 24px 0;
  font-size: 1.5rem;
  @media (min-width: $desktopAditionalModal) {
    text-align: left;
    font-size: 1rem;
    margin: 16px 0 8px 0;
  }
}

.flight-additional-info-baggage-modal-table-header-column {
  font-size: 0.75rem;
  color: $colorTextAdditionalDark;
  text-transform: uppercase;
  padding: 8px 0;
  padding-left: 16px;
  font-weight: bold;
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
}

.flight-additional-info-baggage-modal-table-row {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e3edf3;
  padding: 16px 0;

  &:last-child {
    border-bottom: none;
  }

  @media (min-width: $desktopAditionalModal) {
    flex-direction: row;
    align-items: center;
  }
}

.flight-additional-info-baggage-modal-table-row-cell {
  padding-left: 16px;
  @media (max-width: $desktopAditionalModal - 1) {
    min-width: auto !important;
    width: auto !important;
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    margin-bottom: 8px;
    border-radius: 8px;
    font-size: 0.9rem;
    &:nth-child(2n + 1) {
      background: whitesmoke;
    }
  }
}

.flight-additional-info-baggage-modal-table-cell__text {
  font-size: 0.8rem;
  @media (max-width: $desktopAditionalModal - 1) {
    text-align: right;
    width: auto !important;
    margin-left: 16px;
    flex: 1;
  }
  @media (min-width: $desktopAditionalModal) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.flight-additional-info-baggage-modal-table__mobile-label {
  margin-right: 8px;
  opacity: 0.65;

  @media (max-width: $desktopAditionalModal - 1) {
    flex: 1;
  }

  @media (min-width: $desktopAditionalModal) {
    display: none;
  }
}

.flight-additional-info-baggage-modal-table-row-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 16px 8px 16px;
  @media (min-width: 700px) {
    flex-direction: row;
    align-items: center;
  }
}

.flight-additional-info-baggage-modal-table-row-header-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.25rem;
}

.flight-additional-info-baggage-modal-table-row-header-item > img {
  height: 12px;
  margin-right: 8px;
}

.flight-additional-info-baggage-modal-table-footer {
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  max-width: 1010px;

  div {
    margin: 8px 16px;
  }
}

.flight-additional-info-baggage-modal-baggage-info {
  display: flex;
  flex-direction: column;
  @media (max-width: $desktopAditionalModal - 1) {
    align-items: flex-end;
  }
}

.flight-additional-info-baggage-modal-baggage-info__price {
  @media (min-width: $desktopAditionalModal) {
    margin-right: 10px;
  }
}

.flight-additional-info-baggage-modal-baggage-info__meta {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  @media (max-width: $desktopAditionalModal - 1) {
    align-items: flex-end;
  }
}

.flight-additional-info-baggage-modal-baggage-info__price {
  @media (min-width: $desktopAditionalModal) {
    margin-right: 10px;
  }
}

.flight-additional-info-baggage-modal-baggage-info__meta {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.change-ticket {
  font-size: 14px;
}

.change-ticket-passenger-detail {
  margin-bottom: 10px;
}

.change-ticket-passenger-detail-ul {
  list-style-type: none;
  padding-left: 0px;
  margin: 0;
}

.change-ticket-passenger-detail-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.change-ticket-passenger-detail-li {
  padding-left: 3px;
}

.change-ticket-passenger-detail:last-of-type {
  margin-bottom: 0;
}

.popover {
  max-width: 330px;
  width: 100%;
  background-color: $colorBackgroundPopover;
  color: $colorTextLightest;
  border-radius: 3px;
  position: absolute;
  font-size: 12px;
  padding: 15px 10px;
  box-sizing: border-box;
  z-index: 1;
}

.flight-stream {
  border: 1px solid transparent;
}

.flight-segment-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flight-segment-title {
  display: flex;
  flex: 1;
  align-items: center;
  border: 1px solid $colorBorderBackground;
  border-radius: 17.5px;
  background: $colorBackground;
  margin: 0 10px;
  box-sizing: border-box;
  height: 36px;
  padding: 3px 10px 3px 43px;
}

.flight-segment-title__departure,
.flight-segment-title__arrivals {
  background-repeat: no-repeat;
  background-position-x: 21px;
  background-position-y: 11px;
  background-size: 16px;
}

.flight-segment-title__departure {
  background-image: url("/static/images/ico-plane-departure.svg");
}

.flight-segment-title__arrivals {
  background-image: url("/static/images/ico-plane-arrivals.svg");
}

.flight-segment-date-select {
  cursor: pointer;
}

.flight-segment-title-typeroute {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
}

.flight-segment-date-select-val {
  overflow: hidden;
  display: flex;
  align-items: center;
}

.flight-segment-title-datum {
  font-size: 14px;
  font-weight: bold;
  margin: 0 5px 0 12px;
}

.flight-segment-title-dayofweek {
  font-size: 14px;
}

.flight-segment-title-link {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  font-size: 10pt;
  margin-left: auto;
  cursor: pointer;
  color: $colorTextLink;
  margin-right: 10px;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flight-segment-title-link-toggle-icon {
  margin-left: 4px;
  width: 9px;
  height: 7px;
}

.flight-segment-service-info {
  font-size: 14px;
  font-weight: bold;
  padding-left: 15px;
}

.flight-segment {
  margin: 16px 10px;
  display: flex;
  align-items: flex-end;
}

.flight-segment-type-icon {
  background: url(/static/images/ico-plane.svg) no-repeat;
  background-size: 11px;
  width: 17px;
  height: 17px;
  background-position-y: 3px;
}

.flight-segment-city-time {
  font-size: 14px;
}

.flight-segment-time-from {
  display: flex;
  align-items: center;
}

.flight-segment-time {
  display: flex;
  align-items: center;
  font-size: 16px;
  min-height: 35px;
  font-weight: bold;
}

.flight-time-select-control {
  width: 90px;
  min-height: 35px !important;
  height: 35px;
  border-radius: 4px !important;
  border: 1px solid $colorBorderBackgroundShade2 !important;
  background-color: $colorBackgroundLightest !important;
  box-shadow: none !important;
}

.flight-segment-middle {
  display: flex;
  flex: 3;
  align-items: center;
  min-height: 35px;
  padding-right: 5px;
}

.flight-segment-line {
  border-top: 1px dotted $colorBorderAdditionalLight;
  flex-grow: 1;
  margin: 0 6px;
}

.flight-segment-next-day-warning {
  margin-right: 4px;
  color: $colorTextWarning;
  font-weight: bold;
}

.flight-segment-times-moredays {
  color: $colorTextWarning;
  font-size: 12px;
  margin: 0 5px;
}

.flight-segment-transfers-duration {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.flight-segment-duration {
  text-align: center;
}

.flight-segment-marks {
  margin: 16px 10px 15px 10px;
  display: flex;
}

.flight-segment-airline {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  align-self: stretch;
  @media only screen and (max-width: 480px) {
    display: none;
  }
}

.flight-segment-airline-logos {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  justify-content: space-around;
}

// compensate logos with smaller height
.flight-segment-airline-logo-height {
  min-height: 16px;
  display: flex;
  align-items: flex-start;
}

.flight-segment-airline-logo {
  margin-left: 16px;
  max-height: 16px;
  max-width: 85px;
}

.flight-segment-city {
  margin-bottom: 4px;
}

.flight-segment-city-from {
  display: flex;
  text-transform: uppercase;
  font-size: 18px;
}

.flight-segment-tooltip {
  max-height: 10px;
  line-height: 10px;
}

.flight-segment-marks-transfers {
  flex-grow: 2;
  position: relative;
  text-align: center;
}

.flight-segment-marks-transfers-dots {
  border-top: 1px dashed $colorBorderAdditionalLight;
  // margin: 5px 10px 0 10px;
  position: absolute;
  width: 84%;
  left: 8%;
  top: 11px;
  display: flex;
  justify-content: space-around;
}

.flight-segment-marks-transfers-dots-dot {
  position: relative;
  top: -6px;
  background: $colorBackgroundLightest;
}

.flight-segment-marks-transfers-description {
  text-align: center;
  font-size: 12px;
  color: $colorTextAdditionalLight;
}

.flight-segment-marks-to {
  text-transform: uppercase;
  font-size: 18px;
}

.flight-details {
  border-top: 2px solid $colorBorderBackground;
  border-bottom: 2px dashed $colorBorderBackground;
  text-align: center;
  font-size: 13px;
  padding-top: 15px;
  padding-bottom: 15px;
  @media (min-width: $desktop) {
    display: none;
  }
}

.flight-details-link {
  color: $colorTextLink;
  cursor: pointer;
  text-decoration: underline;
}

.flight-detail-one-way-title {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  margin: 15px 10px 13px 10px;
}

.flight-detail-one-way-toggle {
  float: right;
  width: 19px;
  height: 19px;
  text-align: center;
}

.flight-detail-one-way-toggle img {
  width: 11px;
  height: 11px;
}

.flight-detail-duration {
  height: 40px;
  border-radius: 5px;
  background-color: $colorBackgroundWarningLight;
  margin: 0px 10px 15px 10px;
  box-sizing: border-box;
  padding-top: 13px;
  padding-left: 35px;
  font-size: 14px;
  background-image: url(/static/images/ico-time.svg);
  background-repeat: no-repeat;
  background-position-x: 11px;
  background-position-y: 14px;
  background-size: 13px;
}

.flight-detail-one-way-flight {
  display: flex;
  margin: 0 10px;
  font-size: 14px;
  line-height: 23px;
}

.flight-detail-one-way-flight-icon {
  margin-right: 13px;
}

.flight-detail-one-way-flight-wrapper {
  flex-grow: 1;
  border-left: 1px solid $colorBorderBackgroundShade2;
  box-sizing: border-box;
  padding-left: 12px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.flight-detail-one-way-flight-wrapper__padding-top {
  padding-top: 15px;
}

.flight-detail-one-way-flight-flight {
  position: relative;
  margin-bottom: 20px;
}

.flight-detail-one-way-flight-flight-text-image-wrapper {
  display: flex;
  align-items: center;
}

.flight-detail-one-way-flight-flight-row {
  flex-grow: 1;
  @media (min-width: $desktop) {
    display: flex;
  }
}

.flight-detail-one-way-flight-flight-row-times {
  @media (min-width: $desktop) {
    margin-right: 20px;
  }
}

.flight-detail-one-way-flight-flight-row-destination {
  @media (min-width: $desktop) {
    flex-grow: 1;
  }
}

.flight-detail-one-way-flight-flight-text-image {
  max-width: 90px;
  max-height: 16px;
}

.flight-detail-one-way-transfer {
  border: 1px solid $colorBorderLink;
  height: 40px;
  border-radius: 5px;
  margin: 0px 10px;
  box-sizing: border-box;
  padding-top: 10px;
  padding-left: 35px;
  font-size: 14px;
  background-image: url(/static/images/ico-transfer.svg);
  background-repeat: no-repeat;
  background-position-x: 11px;
  background-position-y: 12px;
  background-size: 13px;
}

.flight-detail-one-way-flight-dot {
  position: absolute;
  left: -18px;
  top: 5px;
  background: $colorBackgroundLightest;
}

.flight-detail-segment-technical-stop {
  margin-top: 8px;
  font-size: 13px;
  line-height: 1.5em;
}

.flight-details-wrapper {
  @media (min-width: $desktop) {
    // display: none;
  }
}

.flight-details-wrapper-mobile {
  display: block;
  @media (min-width: $desktop) {
    display: none;
  }
}

.flight-details-wrapper-desktop {
  display: none;
  @media (min-width: $desktop) {
    display: block;
  }
}

.flight-detail-desktop {
  background: $colorBackgroundLightest;
  padding-top: 10px;
  display: none;
  @media (min-width: $desktop) {
    display: block;
  }
}

.flight-detail-desktop-inner {
  margin: 0 20px 3px 20px;
  box-sizing: border-box;
  border-top: 1px dashed $colorBorderBackgroundShade2;
}

.flight-detail-desktop-links-results {
  position: relative;
  display: flex;
  font-size: 14px;
  margin-left: 0;
  text-align: initial;
}

.flight-detail-desktop-links {
  display: flex;
  font-size: 14px;
}

.flight-prices-links {
  margin-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  @media (min-width: $desktop) {
    width: 173px;
  }
}

.hotel-offer-mini-flight-prices-links {
  width: inherit;
  padding-bottom: 0;
  margin-top: 0;
  margin-left: 10px;
}

.flight-prices-links-price {
  font-size: 22px;
  display: inline-block;
}

.flight-prices-links-price-button {
  display: block;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 0.84px;
  line-height: 20px;
  text-transform: uppercase;
  height: 50px;
  width: 150px;
  margin: 11px auto;
}

.flight-prices-links-price-price-description {
  font-size: 13px;
  margin-top: 0px;
  margin-bottom: 5px;
}

.flight-prices-links-price-sum-description {
  font-size: 12px;

  @media (min-width: $desktop) {
    margin-bottom: 12px;
  }

  @media (max-width: $desktop) {
    font-size: 13px;
    margin-top: 11px;
    margin-bottom: 25px;
    margin-left: 10px;
  }
}

.flight-prices-links-price-mobile-divider {
  display: inline-block;
  margin-left: 12px;
  @media (min-width: $desktop) {
    display: none;
  }
}

.flight-prices-policy-badges {
  display: flex;
  justify-content: center;
}

.flight-prices-links-travel-policy {
  color: $colorTextDarkLink;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: underline;
  font-size: 13px;
}

.flight-prices-links-travel-policy-inline {
  justify-content: flex-start;
}

.travel-policy-icon {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.travel-policy-icon-failed {
  width: 11px;
  height: 11px;
  margin-right: 4px;
}

.size-18 {
  width: 18px;
  height: 18px;
}

.button-flights-showmore {
  display: block;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 0.84px;
  line-height: 16px;
  height: 50px;
  width: 220px;
  margin: 19px auto;
  border-color: $colorBorderLink;
  color: $colorTextLink;
  background: transparent;
  cursor: pointer;
}

.results-wrapper {
  // margin-top: 30px;
}

.results-wrapper-box {
  @media (min-width: $desktop) {
    display: flex;
  }
}

.results-wrapper-box-filters {
  @media (min-width: $desktop) {
    min-width: 255px;
    margin-right: 30px;
  }
}

.results-wrapper-box-rest {
  padding-top: 10px;
  @media (min-width: $desktop) {
    flex-grow: 1;
  }
}

.filters-wrapper {
  position: fixed;
  max-height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transform: translate(-265px);
  transition: 1s ease-in-out;
  max-width: 255px;
  @media (min-width: $desktop) {
    position: relative;
    top: inherit;
    left: inherit;
    z-index: inherit;
    transform: none;
  }
}
.filters-close-btn {
  opacity: 0;
  position: fixed;
  z-index: 9;
  bottom: 30px;
  left: 230px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 $colorBackgroundShade1;
  @media (min-width: $desktop) {
    display: none;
  }
}

.filters-background {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.5s ease;

  &.show {
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }

  @media (min-width: $desktop) {
    display: none;
  }
}

.filters-erase-button {
  position: sticky;
  bottom: 15px;
  margin-left: 53px;
  border-radius: 5px;
  background-color: $colorBackgroundLightest;
  padding: 10px 20px;
  font-size: 15px;
  z-index: 1;
  opacity: 0.9;
  margin-top: 20px;
  /*//position: fixed;
  //bottom: 50px;
  left: 50%;
   (min-width: $desktop) {
    margin-left: -440px;
    border-radius: 5px;
    background-color: $colorMainButtonText;
    padding: 10px 20px;
    font-size: 15px;
    z-index:1;
    opacity:0.9;
    bottom: 0;
    position: sticky;
  }*/
}

.filters-wrapper-show-mobile {
  transform: translate(-10px);
  transition: 1s ease-in-out;
  overflow-y: scroll;
  background-color: $colorBackgroundShade1;
  z-index: 3;
}

.filters {
  background-color: $colorBackgroundShade1;
  border-radius: 0 0 5px 5px;
  font-size: 13px;
}

.filters-box {
  padding: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid $colorBorderFiltersSeparator;
  clear: both;
}

.filters-box:last-of-type {
  border: none;
}

.filters-box-toggle-link:hover {
  opacity: 0.75;
}

.filters-label {
  font-size: 19px;
  display: block;
  margin-bottom: 15px;
}

.filters-label-airports {
  font-size: 17px;
  display: block;
  margin: 15px 0px;
}

.filters-label-baggage {
  font-size: 19px;
  clear: both;
  padding-top: 25px;
  margin: 0;
}

.filters-range-description {
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 13px;
  margin-top: 16px;
}

.filters-transfers {
  font-size: 14px;
  height: 40px;
  width: 90%;
  border-radius: 3px;
  background-color: $colorBackgroundLightest;
  box-shadow: 0 2px 4px 0 $colorBackgroundShade1;
  box-sizing: border-box;
  padding-top: 10px;
  text-align: center;
  position: relative;
  margin-left: 5%;
  margin-right: 5%;
  float: left;
  margin-top: 10px;
}

.filter-airline {
  margin-bottom: 8px;
  display: flex;
}

.filter-airline-checkbox-container {
  justify-content: space-between;
  padding-bottom: 5px;
}

.filter-airline-options-checkbox-label {
  font-size: 12px;
  margin-left: 15px;
}

.filter-airline-options-checkbox {
  height: 15px;
  border-radius: 2px;
}

.filter-airline-options-checkbox-container {
  width: 80%;
  justify-content: flex-start;
  padding: 5px 0px;
}

.filter-airline-checkbox-label {
  width: 85%;
  font-size: 13px;
}

.filter-airline-checkbox-baggage {
  float: right;
  margin-top: 4px;
  margin-right: 0;
  cursor: pointer;
}

.filter-baggage {
  float: right;
  cursor: pointer;
}

.baggage-checkbox-container {
  justify-content: flex-start;
  padding-top: 25px;
}

.baggage-checkbox-label {
  font-size: 19px;
  margin-left: 15px;
}

.filter-airline-name {
  flex-grow: 1;
  user-select: none;
  cursor: pointer;
}

.filter-airline-spacer {
  border-bottom: 1px solid $colorBorderFiltersSeparator;
  margin: 15px 0;
}

.filters-select {
  margin-bottom: 10px;
}

.conditions-close {
  float: right;
  position: relative;
  top: -15px;
  font-weight: bold;
  cursor: pointer;
}

.conditions-go-back {
  float: left;
  top: -32px;
  position: relative;
  font-weight: bold;
  cursor: pointer;
}

.flight-conditions {
  font-size: 14px;
  padding: 25px;
  max-width: 700px;
  background-color: $colorBackgroundLightest;
  min-height: 150px;
  max-height: 400px;
  overflow-y: auto;
}

.flight-contidions-content {
  padding: 24px 32px 32px 32px;
  height: fit-content;
  max-height: 60%;
  max-width: 768px;
}

.flight-conditions-loading {
  display: grid;
  place-items: center;
}

.flight-conditions-list {
  line-height: 2rem;
}

.flight-conditions-condition {
  margin-left: 15px;
}

.flight-conditions-terms {
  min-width: 400px;
  min-height: 400px;
  font-size: 14px;
  padding: 10px 20px 30px 15px;
  max-width: 675px;
  background-color: $colorBackgroundLightest;
  margin-top: 0px;
}

.flight-conditions-passager-type-title {
  display: flex;
  justify-content: space-between;
  font-size: 28px;
  margin-bottom: 15px;
  margin-top: 18px;
  font-weight: bold;
}

.flight-conditions-close-btn {
  cursor: pointer;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
}

.flight-conditions-tarif-title {
  font-size: 15px;
  font-weight: bold;
}

.flight-conditions-basis-title {
  font-size: 16px;
  margin: 15px 0 10px 0;
}

.condition-detail {
  font-size: 12px;
  line-height: 1.33;
  margin-bottom: 10px;
}

.condition-detail-title {
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
  display: block;
  margin: 0px 0 5px 0;
}

.filters-show-mobile {
  border: 1px solid $colorBorderTextWarning;
  position: absolute;
  top: 0;
  left: -10px;
  z-index: 1000;
}

.filters-show-mobile .filters {
  display: block;
}

.flights-sorting-wrapper {
  display: flex;
  display: flex;
  height: 86px;
  justify-content: flex-start;
  border-radius: 10px;
  background: $colorBackgroundShade1;
  margin-bottom: 34px;

  @media (max-width: $desktop) {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    height: 50px;
    border-radius: 6px;
    margin-bottom: 24px;
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    user-select: none;
    cursor: grabbing;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.flights-sorting-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: $colorBackgroundShade1;
  border-radius: 10px;
  height: 50px;
  flex: 1;
  cursor: grab;

  @media (min-width: $desktop) {
    flex-direction: column;
    justify-content: center;
    height: 86px;
    flex: 1;
  }

  @media (max-width: $desktop) {
    padding-left: 15px;
    padding-right: 15px;
  }

  &:last-of-type {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

.flights-sorting-item > div:first-child {
  font-size: 13px;
  margin-top: 0px;
}

.flights-sorting-item > div:nth-child(2) {
  font-size: 22px;
  display: inline-block;
}

.flights-sorting-item > div:nth-child(3) {
  text-align: center;
  font-size: 12px;
  color: $colorTextAdditionalLight;
}

.vl {
  border: 1px solid $colorBorderFiltersSeparator;
  @media (min-width: $desktop) {
    margin-bottom: 28px;
    margin-top: 28px;
  }
}

.flights-sorting-item.selected + .vl,
.vl:has(+ .flights-sorting-item.selected) {
  border-color: $colorBackgroundShade1;
}

.selected {
  background: $colorBackgroundLightest;
  border-radius: 5px;
}

.flights-sorting-item:hover:not(.selected) {
  cursor: pointer;
}

.flights-sorts-wrapper {
  display: flex;
  align-items: center;
}

.flights-sorts {
  margin: 10px 0 0 auto;
}

.flights-sorts .Select-control {
  background-color: transparent;
}

.flights-sorts .Select-value-label {
  color: $colorText !important;
}

.flight-places-times-select {
  position: relative;
  margin-top: -6px;
  font-weight: bold;
  outline: none;
  border: none;
  font-size: 14px;
  color: $colorText;
}

.filter-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  border-radius: 3px 3px 0px 0px;
  width: 100%;
  cursor: pointer;
  text-align: center;
}

.filter-box-tabs {
  display: flex;
  justify-content: space-around;
  font-size: 9pt;
  font-weight: bold;
}

.filter-tab-active {
  cursor: default;
  transition: 0.2s ease-in-out;
  background-color: $colorBackgroundLightest;
}

.filter-range-box {
  padding: 10px;
  border-radius: 0px 0px 3px 3px;
}

.table-header {
  display: inline-block;
}

.table-column {
  display: inline-block;
}

.modal-share-table {
  font-size: 13px;
  text-align: center;
  margin: 50px auto;
  border-collapse: collapse;
  width: 740px;
}

.modal-share-row {
  height: 20px;
}

.modal-share-border {
  border-bottom: 1px solid $colorBorderDarkest;
}

.modal-share-button-row {
  display: flex;
  justify-content: space-between;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: $colorBackgroundTooltipSlider;
  color: $colorTextLightest;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: $colorBackgroundTooltipSlider transparent transparent
    transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.link-input {
  margin-right: 10px;
  width: 65%;
  padding: 2px 5px;
  @media (min-width: $desktop) {
    width: 80%;
  }
}

.modal-share-input-label {
  width: 160px;
  font-size: 14px;
  font-weight: bold;
}

.modal-share-input-wrapper {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: $desktop) {
    justify-content: initial;
  }
}

.background-enter {
  transform: translate(-80%);
  transition: transform 1000ms ease-in-out;
}

.background-enter.background-enter-active {
  transform: translate(0%);
  transition: transform 1000ms ease-in-out;
}

.background-leave {
  transform: translate(0%);
}

.background-leave.background-leave-active {
  transform: translate(-100%);
  transition: transform 1000ms ease-in-out;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 1s;
}

.fade-in-delay {
  opacity: 1;
  animation-name: fadeInOpacityDelay;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 1.5s;
}

@keyframes fadeInOpacityDelay {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.airportsCheckboxContainer {
  justify-content: space-between;
  margin-bottom: 3px;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.airportsCheckboxLabel {
  margin-left: 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
  font-size: 13px;
}

.travel-policies-detail-popup {
  width: 860px !important;
  height: 860px;
  max-height: 95%;
  overflow-y: auto;

  @media (max-width: $desktop) {
    width: 95% !important;
    padding: 8px;
  }
}

.travel-policies-detail-popup__content {
  margin: 32px 30px;
}

.travel-policies-detail-popup__inner {
  height: 700px;
  width: 1200px;
  overflow-y: auto;

  @media (max-width: $desktop) {
    width: 100%;
  }
}

.travel-policies-detail-popup__title {
  position: relative;
  font-size: 28px;
  color: $colorText;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
}

.travel-policies-detail-popup__statusMsg {
  display: flex;
  align-items: center;
  text-align: left;
  border-radius: 2px;
  padding: 12px 18px;
  margin-bottom: 18px;

  .travel-policies-detail-popup__state-icon {
    margin-right: 10px;
    flex-shrink: 0;
  }

  &.passed {
    color: #78ba47;
    background-color: #e4eee6;
  }

  &.failed {
    color: #e34949;
    background-color: #fdf0f0;
  }
}

.travel-policies-detail-popup__travelers {
  display: flex;
  list-style: none;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.travel-policies-detail-popup__traveler {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 5px 10px 0;
  padding: 14px 22px;
  color: $colorText;
  background-color: #{$colorBackground}75;
  border-radius: 4px 4px 0 0;
  font-size: 15px;
  font-weight: bold;
  flex: 1;
}

.travel-policies-detail-popup__traveler--selected {
  background-color: $colorBackground;
}

.travel-policies-detail-popup__state-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-left: 6px;

  &.success {
    background-color: $colorBackgroundSuccess;
  }

  &.fail {
    background-color: $colorBackgroundWarning;
  }
}

.travel-policies-detail-popup__success-state-img {
  width: 12px;
  height: 9px;
}

.travel-policies-detail-popup__failed-state-img {
  width: 14px;
  height: 14px;
}

.travel-policies-detail-popup__rules-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 15px;
}

.travel-policies-detail-popup__rules-table-no-policy {
  text-align: center;
  margin: 32px 0;
}

.travel-policies-detail-popup__rules-table {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;

  th,
  td {
    text-align: center;
    vertical-align: middle;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid $colorBackgroundLightest;
    width: 31%;

    @media (max-width: $desktop) {
      width: 32%;
    }
  }
}

.travel-policies-detail-popup__rules-table__header {
  background-color: $colorBackground;
  cursor: pointer;
  font-weight: bold;
  border-bottom: 10px solid $colorBackgroundLightest;

  th {
    height: 52px;
    padding: 0 12px;
    background-color: $colorBackground;
  }

  @media (max-width: $desktop) {
    th {
      padding: 0 4px;
    }
  }
}

.travel-policies-detail-popup__rules-table__row {
  td {
    height: 52px;
    text-align: center;
    vertical-align: middle;
    border-bottom: 1px solid $colorBackgroundLightest;
    width: 31%;
  }

  td:first-child {
    text-align: left;
  }

  td:last-child {
    width: 7%;

    @media (max-width: $desktop) {
      width: 4%;
    }
  }

  @media (max-width: $desktop) {
    td {
      padding: 6px 4px;
    }
  }
}

.travel-policies-detail-popup--first-values {
  padding-left: 20px;

  @media (max-width: $desktop) {
    padding-left: 0px;
  }
}

.travel-policies-detail-popup__info-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $colorBackgroundPopover;
  color: $colorTextLightest;
  cursor: pointer;
  font-weight: bold;
  margin-left: 8px;
  font-size: 12px;
}

.show-more-flights-wrapper {
  text-align: center;
  background-color: $colorBackgroundLightest;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  padding: 15px;
  cursor: pointer;
  color: $colorTextLink;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.results-booking-form-loader {
  display: flex;
  justify-content: center;
}
