.profile-title-row {
  display: flex;
  align-items: center;
  width: 100%;
}

.profile-reservation-button {
  margin-left: 20px;
}

.profile-modal {
  box-sizing: border-box;
  height: fit-content;
  max-height: 95%;
  width: 550px !important;
  padding: 32px !important;
  @media (max-width: $desktop) {
    padding: 16px;
    max-height: 98%;
    width: 95% !important;
  }
}

.change-password-modal {
  height: 370px;
  height: fit-content; // not works in Firefox
  @media (max-width: $desktop) {
    height: 420px;
    height: fit-content;
  }
}

.input-wrapper-error {
  margin-top: 10px;
  color: $colorTextWarning;
  transition: all 0.3s ease-in-out;
}

.my-account-modal,
.passenger-modal {
  height: 560px;
  height: fit-content;
  @media (max-width: $desktop) {
    height: 680px;
    height: fit-content;
    overflow: auto;
  }
}

.identity-document-modal {
  height: 430px;
  height: fit-content;
  @media (max-width: $desktop) {
    height: 520px;
    height: fit-content;
  }
}

.loyalty-card-modal {
  height: 300px;
  height: fit-content;
  @media (max-width: $desktop) {
    height: 350px;
    height: fit-content;
  }
}

.profile-modal__container {
  width: 80vw;
  padding: 12px;
  @media (min-width: $desktop) {
    width: 650px;
  }
}

.profile-modal__collapsible {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px;
  border-top: 1px solid $colorBorderSearchFormAdditional;
}

.profile-modal__chevron {
  width: 20px;
  height: 20px;
  font-weight: bold;
  margin-left: -5px;
  cursor: pointer;

  &.chevron--right {
    transform: rotate(90deg);
  }
}

.profile-modal__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-modal__collapsible-title {
  font-size: 20px;
  margin-top: 0;
  margin-left: 5px;
  font-weight: bold;
}

.profile-modal__title {
  font-size: 28px;
  margin-top: 0;
}

.profile-modal__save-btn {
  margin-top: 4px;
  margin-right: 10px;
  margin-left: 10px;
  @media (max-width: $desktop) {
    margin-left: 0;
  }
}

.profile-modal__button-section {
  padding-bottom: 15px;
  padding-top: 15px;
}

.requiredInfo-namePrefix-input {
  flex-grow: 1;
  border-radius: 3px;
}

.requiredInfo-namePrefix {
  display: flex;
  align-items: center;
}

.simple-input__radio-label {
  display: flex;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 16px;
}

.profile-container {
  @media (min-width: $desktop) {
    display: flex;
    justify-content: space-between;
  }
  margin-top: 32px;
}

.profile-select-input {
  flex-grow: 1;
}

.passportInfo-erase-button {
  display: inline-block;
  margin-left: 15px;
  margin-bottom: 15px;
  font-size: 12px;
  border: 1px solid $colorBorderBackgroundShade1;
  border-radius: 5px;
  vertical-align: middle;
  text-align: center;
  height: 7%;
  width: 80%;
  line-height: 20px;
  padding: 7px;
  cursor: pointer;
  @media (min-width: $desktop) {
    width: 30%;
    margin-bottom: 0;
  }

  &:hover {
    background-color: $colorBackgroundShade1;
  }
}

.profile-block-container {
  margin-top: 60px;
  @media (min-width: $desktop) {
    width: 47%;
    margin-right: 45%;
  }
}

.profile-submit-container {
  width: 100%;
  margin-bottom: 25px;
}

.profile-container .react-tel-input {
  // to clean
  width: inherit;
  height: 47px;
}

.profile-input-date-container {
  display: flex;
  width: 100%;
}

.profile-side-bar {
  flex: 1;
  position: relative;
  box-sizing: border-box;
  background-color: $colorBackgroundShade1;
  padding: 20px 10px;
  width: 256px;
  min-width: 256px;
  max-width: 256px;
  border-radius: 4px;
  margin-right: 18px;

  .profile-side-bar__items {
    padding: 0;
    font-size: 14px;
    .profile-side-bar__item {
      cursor: pointer;
      border-radius: 4px;
      padding: 9px;
      &.active,
      &:hover {
        background-color: $colorBackground;
      }
    }
  }
  .sticky {
    position: sticky;
    top: 20px;
  }

  @media (max-width: $desktop) {
    display: none;
  }
}

.profile-side-bar__title {
  padding: 0 10px;
  font-size: 18px;
  margin-bottom: 12px;
}

.profile-main {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: $colorBackground;
}

.profile-main__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  margin-bottom: 13px;
  font-weight: bold;
  padding: 0 20px;
}

.profile-main__title-add-new {
  @extend .fake-link;
  font-size: 13px;
  font-weight: normal;
}

.profile-main__card {
  background-color: $colorBackgroundLightest;
  padding: 25px 30px;
  border-radius: 4px;
  margin-bottom: 25px;
  box-shadow: 0 3px 7px $colorBoxShadow;

  @media (max-width: $desktop) {
    padding: 15px 15px;
  }
}

.profile-main__card--empty {
  margin: 30px 0;
  text-align: center;
}

.profile-main__card-tabs {
  display: flex;
  border-bottom: 1px solid $colorBackgroundShade1;
}

.profile-main__card-tab {
  cursor: pointer;
  padding: 14px 32px;
  font-size: 14px;
  &.active {
    border-bottom: 2px solid $colorBorderLink;
  }
}

.profile-main__card-desc {
  margin-top: 15px;
  margin-bottom: 5px;
}

.profile-main__card-row {
  font-size: 14px;
  padding: 7px 0;
  display: flex;
}

.profile-main__card-row-label {
  min-width: 132px;
}

.profile-main__card-row-value {
  font-weight: 600;
  word-break: break-word;
}

.fake-link {
  cursor: pointer;
  color: $colorTextLink;
  text-decoration: underline;
}

.profile-main__card-divider {
  width: 75%;
  height: 1px;
  background-color: $colorBackgroundShade1;
  margin: 12px 0;
}

.profile-main__card-action-btn {
  font-size: 13px;
  font-weight: bold;
  padding: 14px;
  background-color: $colorBackgroundLightest;
  color: $colorBorderLink;
  border: 1px solid $colorBorderLink;
  border-radius: 4px;
  min-width: 144px;
  margin-top: 15px;
  margin-bottom: 5px;
  cursor: pointer;
}

.profile-table {
  * {
    box-sizing: border-box;
  }

  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  .profile-table__cell {
    padding: 16px 10px;
    text-align: left;
  }

  &.profile-table-2-cols .profile-table__cell {
    width: 45%;
  }

  @media (max-width: $desktop) {
    .profile-table__more {
      display: none;
    }
    .profile-table__cell {
      padding: 16px 10px;
      max-width: 0;
      word-wrap: break-word;
    }
  }
  tr {
    border-bottom: 1px solid $colorBackgroundShade1;
    &:last-child {
      border: none;
    }
    &:not(.no-hover):hover {
      background-color: $colorBorderBackgroundShade1;
    }

    &.no-hover {
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.profile-table__num-col {
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  padding-left: 10px;
}

.profile-table__menu-cell {
  width: 38px;
  min-width: 38px;
  max-width: 38px;
  position: relative;
}

.profile-table__menu-btn {
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  height: 20px;
  margin-right: 0;
  margin-left: auto;
  padding: 0 10px;
}

.profile-table__menu {
  position: absolute;
  right: 0;
  z-index: 1;
  background-color: $colorBackgroundLightest;
  box-shadow: 0 3px 6px #{$colorBackgroundDarkest}0d;
  border-radius: 4px;
  width: 150px;
}

.profile-table__menu-item {
  cursor: pointer;
  padding: 12px;

  &:hover {
    background-color: $colorBorderBackgroundShade1;
  }
}

.simple-input__wrapper {
  margin-bottom: 20px;

  &.simple-input__wrapper--error {
    margin-bottom: 5px;

    .simple-input__label {
      color: $colorTextWarning;
    }

    .simple-input__input {
      border-color: $colorBorderTextWarning;
    }
  }

  @media (min-width: $desktop) {
    margin-left: 0;
  }

  .simple-input__input-wrapper {
    width: 100%;
  }

  .simple-input__inner {
    width: 100%;
    display: flex;

    @media (max-width: $desktop) {
      display: block;
    }
  }

  .simple-input__label {
    display: flex;
    align-items: center;
    font-size: 14px;
    min-width: 132px;
    @media (max-width: $desktop) {
      margin-bottom: 6px;
    }
  }

  .simple-input__label--required {
    color: $colorTextLink;
  }

  .simple-input__input {
    border: 1px solid $colorBorderBackgroundShade2;
    border-radius: 3px;
    background-color: $colorBackgroundLightest;
    box-shadow: inset 2px 2px 7px 0 $colorBackground;
    height: 45px;
    width: 100%;
    font-size: 14px;
    padding-left: 8px;
    box-sizing: border-box;
    outline-width: 2px;
    display: flex;
    @media (min-width: $desktop) {
      //width: inherit;
      flex-grow: 1;
    }
  }

  .simple-input__input::placeholder {
    color: $colorTextAdditionalLight;
  }

  .simple-input__input--filled {
    border: 1px solid $colorBorderInput;
  }

  .simple-input__error {
    display: flex;
    color: $colorTextWarning;
    padding-bottom: 3px;
    font-size: 12px;
    transition: all 1s ease-in-out;
    margin-left: 132px;
    @media (max-width: $desktop) {
      margin-left: 0;
    }
  }

  .DayPickerInput {
    width: 100%;
  }
}

.deletion-confirmation {
  padding: 32px 30px !important;
  height: fit-content;

  .deletion-confirmation__title {
    font-size: 28px;
    font-weight: bold;
    margin: 0 0 20px;
  }

  .deletion-confirmation__text {
    margin-bottom: 20px;
  }

  .deletion-confirmation__delete-btn {
    cursor: pointer;
    color: $colorTextLightest;
    font-weight: bold;
    font-size: 13px;
    margin-right: 8px;
    border: none;
    background-color: $colorBackgroundDanger;
    border-radius: 4px;
    padding: 14px 42px;
  }

  .deletion-confirmation__cancel-btn {
    cursor: pointer;
    font-weight: bold;
    font-size: 13px;
    margin-right: 8px;
    border: none;
    background-color: $colorBackgroundLightest;
    border-radius: 4px;
    padding: 14px 42px;
  }
}

.profile-modal__open-remove-btn {
  color: $colorTextDanger;
  background-color: $colorBackgroundLightest;
  cursor: pointer;
  font-weight: bold;
  font-size: 13px;
  margin-right: 8px;
  border: none;
  padding: 14px 14px;
}

.profile-modal__save {
  color: $colorTextDanger;
  background-color: white;
  cursor: pointer;
  font-weight: bold;
  font-size: 13px;
  margin-right: 8px;
  border: none;
  padding: 14px 34px;
  margin-top: 25px;
  margin-bottom: 10px;
  border: solid 1px $colorTextDanger;
  border-radius: 4px;
}

.dateInput {
  position: relative;
  display: flex;
  align-items: center;
  .dateInput__calendar-icon {
    position: absolute;
    right: 10px;
    max-height: 35px;
  }
}
