.reservations-container {
  box-shadow: 0px 0px 6px #0000001a;
  overflow: auto;
}
.reservations-table-container {
  min-height: 540px;
}

.reservations-no-reservation-container {
  min-height: 540px;
  text-align: center;
}

.reservations-no-reservation-message {
  top: 40%;
  position: relative;
}

.reservations-image-empty-table {
  margin-bottom: 25px;
}

.reservations-title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: $desktop) {
    margin: -24px 0 8px 0;
  }
}

.reservations-table {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-bottom: 25px;
}

.reservations-table-header {
  display: flex;
  align-items: center;
  background: #e3edf3;

  @media (max-width: $desktop - 1) {
    display: none;
  }
}

.reservations-table-header-column {
  font-size: 0.75rem;
  color: $colorTextAdditionalDark;
  text-transform: uppercase;
  padding: 8px 0;
  padding-left: 16px;
  font-weight: bold;
}

.reservations-table-row {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $colorBackgroundShade2;
  padding: 16px 0;
  text-decoration: none;
  color: inherit;

  @media (min-width: $desktop) {
    flex-direction: row;
    align-items: center;

    &:hover {
      background: $colorBackground;
    }
  }

  &:hover {
    cursor: pointer;

    .fas {
      transform: translateX(-20px);
    }
  }
}

.reservations-table-flight-segments-list {
  .reservations-table-row-cell {
    padding: 8px 0;
    padding-left: 16px;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $colorBackgroundShade1;
    }
  }
}

.reservations-table-row-cell {
  padding-left: 16px;

  @media (max-width: $desktop - 1) {
    min-width: auto !important;
    width: auto !important;

    padding: 0 16px;

    &:not(.reservations-table-row__title):not(.reservations-table-row__destination) {
      padding: 8px 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 12px 16px;
      margin-bottom: 8px;
      border-radius: 8px;
      font-size: 0.9rem;

      &:nth-child(2n + 1) {
        background: $whitesmoke;
      }
    }
  }
}

.reservations-table-row-cell-tworow-wrapper {
  display: flex;
  flex-direction: column;
}

.reservations-table-row-cell__text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.8rem;

  @media (max-width: $desktop - 1) {
    width: auto !important;
  }
}

.reservations-table-row-cell__mobile-label {
  margin-right: 8px;
  opacity: 0.65;

  @media (min-width: $desktop) {
    display: none;
  }
}

.reservation-table-row-header {
  @media (max-width: $desktop - 1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0;
  }

  .fas {
    font-size: 1.5rem;
    transform: translateX(-25px);
    transition: transform 0.3s;

    @media (min-width: $desktop) {
      display: none;
    }
  }
}

.reservations-table-row-header-list {
  display: flex;
  flex-direction: column;
}

.reservation-table-row-header__text {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.reservations-table-row__title {
  text-transform: capitalize;
  color: $colorText;

  @media (max-width: $desktop - 1) {
    font-size: 1.2rem;
    font-weight: bold;
  }
}

.reservations-table-row__destination {
  font-size: 1rem;
  color: $colorText;
}

.reservations-table-row-departure-icon-wrapper {
  margin-right: 8px;
  width: 12px;
  display: inline-block;

  .reservations-table-row-arrival-icon {
    width: 12px;
  }
}

/*
 STATUS 
*/
.reservations-table-row-cell__status {
  font-size: 0.75rem;
  border-radius: 4px;
  padding: 4px 8px;
  width: fit-content;
}

.reservations-table-row-cell__status--paid,
.reservations-table-row-cell__status--active,
.reservations-table-row-cell__status--active-paid,
.reservations-table-row-cell__status--active-unpaid,
.reservations-table-row-cell__status--active-unaproved {
  color: $colorBackgroundSuccess;
  background: $colorBackgroundSuccessLight;
}

.reservations-table-row-cell__status--processing,
.reservations-table-row-cell__status--need-payment,
.reservations-table-row-cell__status--need-confirmation,
.reservations-table-row-cell__status--processing-long {
  color: $statusMerelyOkColor;
  background: $statusMerelyOkBackground;
}

.reservations-table-row-cell__status--issued,
.reservations-table-row-cell__status--canceled,
.reservations-table-row-cell__status--to-refund {
  color: $statusInfoColor;
  background: $statusInfoBackground;
}

.reservations-table-row-cell__status--canceled {
  color: $statusInfoColor;
  background: $statusInfoBackground;
}

.reservations-table-row-cell__status--unsuccessful {
  color: $statusProblemColor;
  background: $statusProblemBackground;
}

/*
  TOOLTIP
*/
.reservation-table-tooltip {
  border: none;
  @media (max-width: $desktop - 1) {
    display: none;
  }
}

.no-select {
  outline: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
