.secondary-color {
  color: $colorTextLink;
}

.normal-weight {
  font-weight: 400;
}

.clearfix {
  clear: both;
}

.no-margin {
  margin: 0 !important;
}

.left {
  text-align: left !important;
}

.center {
  text-align: center !important;
}

.hidden {
  display: none !important;
}

.relative {
  position: relative;
}

// rc-slider
.rc-slider {
  position: relative;
  margin: 6px;
  height: 14px;
  padding: 5px 0;
  border-radius: 6px;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: $colorOutOfRangeSlider;
  height: 4px;
  border-radius: 6px;
}
.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: $colorWithinRangeSlider;
}
.rc-slider-handle {
  position: absolute;
  margin-top: -5px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px $colorBorderSlider;
  background-color: $colorBackgroundLightest;
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}
.rc-slider-handle:hover {
  border-color: $colorBorderSliderHover;
}
.rc-slider-handle:active {
  border-color: $colorBorderSliderHover;
  box-shadow: 0 0 5px $colorBackgroundSlider;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.rc-slider-handle:focus {
  border-color: $colorBorderSliderHover;
  box-shadow: 0 0 0 5px $colorBackgroundSliderLighter;
  outline: none;
}
.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: $colorTextAdditionalDark !important;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}
.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  margin-bottom: -7px;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}
.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
.rc-slider-tooltip-hidden {
  display: none;
}
.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}
.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: $colorTextLightest;
  text-align: center;
  text-decoration: none;
  background-color: $colorBackgroundTooltipSlider;
  border-radius: 6px;
  box-shadow: 0 0 4px $colorBoxShadow;
}
.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: $colorBackgroundTooltipSlider;
}

.lds-ring {
  display: inline-block;
  position: fixed;
  z-index: 1001;
  top: 50%;
  left: 50%;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid $colorMainButtonGradientEnd;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $colorMainButtonGradientEnd transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
a .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.lds-ring-mini {
  display: inline-block;
  position: inherit;
  margin: 0;
  top: inherit;
  left: inherit;
}

.lds-ring-mini div {
  box-sizing: border-box;
  display: block;
  position: relative;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid $colorMainButtonGradientEnd;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $colorMainButtonGradientEnd transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
a .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner_overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $colorBackgroundDarkest;
  opacity: 0.2;
  z-index: 1000;
  overflow: hidden;
}

.tooltip-background {
  background-color: $colorBackgroundTooltip;
}
