.hotel-offer {
  margin-bottom: 22px;
}

.hotel-offer-empty {
  background: $colorBackgroundLightest;
  display: block;
  padding: 25px;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 22px;
  line-height: 1.6;
  margin-top: 30px;
}

.hotel-offer-mini {
  background-color: $colorBackgroundLightest;
  width: 350px;
  position: relative;
  top: -20px;
  left: -175px;
  padding: 0;
  border-radius: 17.5px;
  display: block;
  z-index: 999999999;
}

.hotel-offer-mini-title {
  margin-left: 0px;
  margin-right: 0px;
  z-index: 999999999;
}

.hotel-offer-content {
  margin: 13px 10px 18px 10px;
  display: flex;
}

.hotel-offer-mini-extra {
  margin: 10px 10px 0px 10px;
}

.hotel-offer-mini-content {
  display: flex;
  padding: 10px;
  align-items: center;
}
.hotel-offer-mini-content-photos {
  flex: 1;
}
.hotel-offer-mini-content-prices {
  flex: 1;
}

.hotel-offer-title {
  background-image: url("/static/images/ico-hotel-results.svg");
  background-repeat: no-repeat;
  background-position-x: 18px;
  background-position-y: 11px;
  background-size: 16px;
  font-weight: bold;
  padding-left: 44px;
  font-size: 14px;
}

.hotel-offer-image-wrapper {
  width: 127px;
  height: 85px;
  border-radius: 7px;
  background: $colorBackground;

  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-left: 1px;
  margin-bottom: 10px;

  background-image: url("/static/images/icon-empty-hotel.svg");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: 66px;

  @media (min-width: $desktop) {
    width: 182px;
    height: 121px;
    margin-bottom: inherit;
  }
}

.hotel-offer-image-main {
  width: 100%;
  cursor: pointer;
}

/* Shine */
.hotel-offer-image-main-figure {
  position: relative;
  margin: 0;
  padding: 0;
}
.hotel-offer-image-main-figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  cursor: pointer;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.hotel-offer-image-main-figure:hover::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}

.hotel-offer-image-more {
  position: absolute;
  right: 0px;
  bottom: 12px;
  padding: 3px 11px;
  color: white;
  background: grey;
}

.hotel-offer-content-description {
  flex: 1;
  margin-left: 16px;
  font-size: 14px;
  line-height: 1.5;
  color: $colorTextAdditionalDark;
  margin-right: 3px;
}

.hotel-offer-content-description-show-on-map {
  font-size: 12px;
  color: $colorTextAdditionalLight;
  cursor: pointer;
  text-decoration: underline;

  background-image: url("/static/images/ico-map-mini.svg");
  background-repeat: no-repeat;
  background-position-x: 0px;
  background-position-y: 2px;
  background-size: 15px;
  padding-left: 21px;
  font-stretch: extra-condensed;
  margin-bottom: 5px;

  display: none;

  &:hover {
    text-decoration: none;
  }

  @media (min-width: $desktop) {
    float: right;
    margin-bottom: 0px;
    display: block;
  }
}

.hotel-offer-content-description-show-on-map-text {
  @media (max-width: $desktop) {
    background-image: url("/static/images/ico-map-mini.svg");
    background-repeat: no-repeat;
    background-position-x: 0px;
    background-position-y: 3px;
    background-size: 15px;
    padding-left: 21px;
    text-decoration: underline;
    margin-bottom: 2px;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
  @media (min-width: $desktop) {
    pointer-events: none;
  }
}

.hotel-offer-choose-link {
  text-decoration: none;
}

.modal-map {
  width: 360px;
  height: 560px;
  @media (min-width: $desktop) {
    width: 560px;
  }
}

.hotels-image-gallery-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50px;
}

.hotels-image-gallery-image {
  max-height: 95%;
  max-width: 95%;
}

.hotels-image-gallery-description {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 50px;
  background-color: $colorBackgroundLightest;

  animation-name: slideUp;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-duration: 0.55s;
}

@keyframes slideUp {
  0% {
    transform: translateY(60px);
  }
  100% {
    transform: translateY(0);
  }
}

.hotels-image-gallery-description-text {
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  padding: 3px 0;
}

.hotels-image-gallery-button {
  margin: 0 36px;
  border-radius: 3px;
  padding: 6px 16px;
  border-width: 0px;
}

.hotels-image-gallery-close-button {
  position: absolute;
  right: 16px;
  margin: 0;
  cursor: pointer;
}

.hotel-room-picker {
  padding: 20px 20px 15px 25px !important;
}

.hotel-room-picker-header {
  display: flex;
  align-items: center;
  font-size: 12px;
  position: relative;
  top: -5px;
}

.hotel-room-picker-header-description {
  flex: 1;
}

.hotel-room-picker-header-prices {
  width: 160px;
  text-align: center;
}

.hotel-room-picker-header-selected {
  width: 90px;
  text-align: center;
}

.hotel-room-picker-one-room {
  display: flex;
  border-bottom: 1px solid $colorBorderBackground;
  padding: 14px 0;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }
}

.hotel-room-picker-one-room-description {
  flex: 1;
}

.hotel-room-picker-one-room-description-title {
  font-size: 14px;
  font-weight: bold;

  &::first-letter {
    text-transform: uppercase;
  }
}

.hotel-room-picker-one-room-description-information {
  font-size: 12px;
  margin-top: 5px;
}

.hotel-room-picker-one-room-prices {
  width: 120px;
  text-align: center;
}

.hotel-room-picker-one-room-prices-description {
  font-size: 12px;
}

.hotel-room-picker-one-room-prices-price {
  font-weight: bold;
}

.hotel-room-picker-one-room-selected {
  width: 90px;
  text-align: center;
}

.hotel-room-picker-one-room-selected-disabled {
  opacity: 0.35;
  cursor: not-allowed !important;
  user-select: none;
}

.hotel-recapitulation-summary-line {
  display: flex;
  font-size: 14px !important;
  line-height: 1.33 !important;
  padding-bottom: 10px;
}

.hotel-recapitulation-summary-number {
  margin: auto 5px auto 0;
}

.hotel-recapitulation-summary-line-description {
  flex: 1;
  font-size: 14px !important;
  line-height: 1.33 !important;
}

.hotel-recapitulation-summary-line-price {
  width: 90px;
  text-align: right;
  font-size: 16px !important;
  line-height: 1.33 !important;
}

.hotel-result-wrapper {
  margin-top: 10px;
}

.result-confirmation-hotel {
  max-width: 630px;
  border: none !important;
  justify-content: center;

  .button-result-finish {
    margin-left: 0px;
  }
}
