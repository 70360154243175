html {
  font-family: $base-font-family;
  background-color: $colorBackground;
  color: $colorText;
  height: 100%;
}

body {
  margin: 0;
  font-family: Muli, Arial, sans-serif;
  width: 100%;
}

a:visited {
  color: inherit;
}

.no-scroll {
  overflow: hidden !important;
}

// universal
.universal-content-box {
  background-color: $colorBackgroundLightest;
  border-radius: 5px;
  padding: 15px;
  box-sizing: border-box;
  margin-top: 20px;
  @media (min-width: $desktop) {
    padding: 30px;
  }
}

.email-confirmation-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.email-confirm-page-container {
  margin: auto 0;
  width: 670px;
}

.universal-content-box-no-padding {
  background-color: $colorBackgroundLightest;
  border-radius: 5px;
  box-sizing: border-box;
  margin-top: 20px;
}

.universal-h1 {
  margin-top: 0;
}
.universal-h3 {
  margin-bottom: 10px;
  margin-top: 10px;
}
.universal-h-description {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 14px;
}

.billing-info-registration-checkbox > div > div {
  width: 40px;
}
.billing-info-registration-checkbox > div {
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
}

.main-wrapper {
  font-family: Muli, Arial, sans-serif !important;
  color: $colorText !important;
}
