.footer-payment {
  background-color: $colorBackgroundLightest;
  font-size: 14px;
}

.fottext {
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 23px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: $desktop) {
    margin-top: 90px;
    margin-bottom: 70px;
  }
}

.footer-payments {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 5px 0 15px 0;
}
.footer-payments-payment {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $colorBackgroundFooterPayments;
  flex-grow: 1;
  margin: 5px;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  height: 50px;
  width: 40%;
  @media (min-width: $desktop) {
    width: inherit;
  }
}

.footer-payments-payment-verifiedvisa {
  background-image: url(/static/images/f1.png);
}
.footer-payments-payment-securecode {
  background-image: url(/static/images/f2.png);
}
.footer-payments-payment-visa {
  background-image: url(/static/images/f3.png);
}
.footer-payments-payment-mastercard {
  background-image: url(/static/images/f4.png);
}
.footer-payments-payment-jcb {
  background-image: url(/static/images/f5.png);
}
.footer-payments-payment-dinersclub {
  background-image: url(/static/images/f6.png);
}

.footer-allrights {
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 23px;
  text-align: center;
  padding-bottom: 30px;
  background: $colorBackgroundLightest;
}
.footer-allrights-text {
  border-top: 1px solid $colorBorderBackground;
  padding-top: 30px;
  margin-left: 15px;
  margin-right: 15px;
}
.footer-allrights-text > a {
  color: $colorTextLink;
  font-size: 14px;

  &:hover {
    text-decoration: none;
  }
}

.Table__itemCount {
  /* margin-top: 10px; */
  font-size: 14px;
}

.Table__pageButton {
  font-size: 15px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.Table__pageButton:disabled {
  cursor: not-allowed;
  color: $colorTextAdditionalDark !important;
}

.debug-bar-container {
  width: 600px;
  padding: 20px;
  box-sizing: border-box;
  z-index: 1;
}

.footer-container {
  text-align: center;
}

.footer-rights {
  color:grey;
  font-size: 10px;
}

