.special-offers {
  @media (min-width: $desktop) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.DayPicker-Month {
  margin-right: 30px;
}

.special-offers-offer {
  // background: $colorMainButtonText;
  // height: 241px;
  margin: 20px 10px 0 10px;

  background-image: linear-gradient(
    135deg,
    transparent 15px,
    $colorBackgroundLightest 15px
  );
  padding: 10px;
  position: relative;

  text-align: center;

  @media (min-width: $desktop) {
    flex: 1 0 26%;
  }
}

.special-offers-offer-h3 {
  margin-bottom: 5px;
}

.special-offers-offer-price {
  font-size: 22px;
  font-weight: bold;
}

.special-offers-offer-from {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  font-size: 13px;
  margin-bottom: 30px;
}

.special-offers-offer-from-destination {
  display: flex;
  justify-content: center;
}

.special-offers-offer-ico-plane {
  margin-right: 10px;
  transform: rotate(225deg);
}
