.center-content {
  text-align: center;
}

.special-offers-headline {
  margin: 30px 0;
  text-align: center;
}

.best-offer-passengers-properties {
  @extend .header-search-form-properties;
  z-index: 3;
}

.best-offer-passengers-properties-top {
  @extend .header-search-form-properties-top;
}

.best-offer-passengers-inner-field-value {
  @extend .header-search-form-inner-field-value;
}

.best-offer-passengers-inner-field-additional {
  @extend .header-search-form-inner-field-additional;
}

.best-offer-passengers-properties-table {
  @extend .header-search-form-properties-table;
}

.best-offer-passengers-properties-row {
  @extend .header-search-form-properties-row;
}

.best-offer-passengers-properties-class-name {
  @extend .header-search-form-properties-class-name;
}

.best-offer-passengers-properties-class-select {
  @extend .header-search-form-properties-class-select;
}

.best-offer-options {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-bottom: 60px;

  @media (min-width: 700px) {
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 40px;
  }
}

.best-offer-itinerary {
  display: flex;
  flex-flow: row;
  justify-content: center;
  margin: 20px 0;
  width: 50%;

  @media (min-width: 700px) {
    padding-top: 20px;
  }
}

.best-offer-itinerary__origin,
.best-offer-itinerary__destination {
  font-weight: 700;
}

.best-offer-itinerary__type {
  margin: 0 20px;
}

.best-offer-title-container {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.2px;
  text-align: center;

  margin-bottom: 38px;
  margin-top: 38px;
}

.best-offer-title-text {
  margin: auto;
}

.best-offer-title-line {
  border-bottom: 1px solid $colorBorderBackgroundShade2;
  width: 40%;
}

.best-offer-instruction-text {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
}

.best-offer-flight-box {
  border: 2px solid transparent;
  box-sizing: border-box;
  padding: 16px;
  margin-bottom: 18px;
  border-radius: 5px;
  background-color: $colorBackgroundLightest;
  cursor: pointer;
  &:hover {
    border: 2px solid $colorBorderLink;
  }
  &.selected {
    border: 2px solid $colorBorderLink;
  }
  &.unselected {
    opacity: 0.5;
  }

  @media (min-width: $desktop) {
    padding: 32px;
  }
}

.best-offer-title {
  height: 35px;
  border: 1px solid $colorBorderBackgroundShade1;
  border-radius: 17.5px;
  background-color: $colorBackground;
  line-height: 35px;
  box-sizing: border-box;
}

.best-offer-title-layover {
  height: 15px;
  width: 99px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.2px;
  float: right;
}

.best-offer-information-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (min-width: $desktop) {
    flex-wrap: none;
  }
}

.best-offer-date-time-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (min-width: $desktop) {
    margin-left: 15px;
    width: 25%;
    flex-direction: column;
  }
}

.best-offer-date-time {
  height: 28px;
  font-size: 16px;
  letter-spacing: 0.1px;
  line-height: 20px;
}

.best-offer-date-time-date {
  font-size: 20px;
  font-weight: bold;
}

.best-offer-date-time-different-date {
  color: $colorTextWarning;
  margin-right: 10px;
  font-size: 11px;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  @media (min-width: $desktop) {
    margin-left: 5px;
    flex-grow: 0;
  }
}
.best-offer-date-time-times-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media (min-width: $desktop) {
    margin-top: auto;
    margin-left: 5px;
    flex-grow: 0;
  }
}
.best-offer-date-time-time {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1px;
}

.best-offer-divider-line {
  height: 1px;
  width: 30px;
  background-color: $colorBackgroundAdditionalLight;
  margin: 0px 5px;
}

.best-offer-logo-duration-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (min-width: $desktop) {
    flex-direction: column;
    width: 25%;
  }
}
.best-offer-descriprtion {
  display: flex;
  align-items: center;
  width: 70%;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 20px;
  @media (min-width: $desktop) {
    width: 25%;
  }
}

.best-offer-baggage-row {
  display: flex;
}

.best-offer-link {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  @media (min-width: $desktop) {
    width: auto;
    align-items: center;
  }
}

.best-offer-detail {
  width: 100%;
  &.with-margin {
    margin-top: 50px;
  }
}

.best-offer-flight-duration {
  font-size: 14px;
  font-weight: initial;
}

.best-offer-flight-duration-text {
  font-weight: bold;
}

.best-offer-flight-detail-logo-container {
  width: 100px;
}

.best-offer-flight-detail-logo-image {
  margin-left: 16px;
  max-height: 16px;
  max-width: 85px;
}

.best-offer-calendar {
  clear: both;
  @media (min-width: $desktop) {
    display: block;
    min-height: 310px;
  }
}

.best-offer-passengers-selection {
  @media (min-width: $desktop) {
    width: 36%;
  }
}
