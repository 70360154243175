.styled-label {
  margin-left: 270px;
}
.styled-input {
  display: block;
  margin: 0 auto;
  width: 400px;
  border: 1px solid $colorBorderText;
  border-radius: 3px;
  background-color: $colorBackgroundLightest;
  box-shadow: inset 2px 2px 7px 0 $colorBackground;
  padding: 14px 0px 13px 16px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: $colorTextAdditionalLight;
}
