.container {
  max-width: 1010px;
  margin: 0 5px;

  @media (min-width: $desktop) {
    margin: 10px auto;
  }
}

.my-profile-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.my-profile-block-container {
  width: 100%;
  @media (min-width: $desktop) {
    width: 47%;
  }
}

.my-profile-odd-container {
  @media (min-width: $desktop) {
    margin-left: 50px;
  }
}

.my-profle-select-label {
  width: 160px;
  @media (min-width: $desktop) {
    display: inline-block !important;
  }
}

.my-profile-select-wrapper {
  width: 100%;
  display: inline-block;
  @media (min-width: $desktop) {
    width: 64%;
  }
}

.title-row {
  display: flex;
  width: 100%;
}

.flex-wrapper {
  display: flex;
}

.custom-input-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.custom-select-control {
  border: 1px solid $colorBorderInputUnfilled !important;
  border-radius: 3px !important;
  box-shadow: inset 2px 2px 7px 0 $colorBackground !important;
  font-size: 14px;
  height: 45px;
  background-color: #ffffff !important;

  &:hover {
    border-color: $colorBorderBackgroundShade2 !important;
  }

  &.custom-select-control--filled {
    border: 1px solid $colorBorderInput !important;
  }

  &.custom-select-control--disabled {
    border-color: $colorBorderAdditionalLight !important;
  }

  &.custom-select-control--error {
    border-color: $colorBorderTextWarning !important;
  }
  &.custom-select-control--error-and-touched {
    border-color: $colorBorderTextWarning !important;
  }
}

.simple-select-option {
  color: $colorTextDarkest !important;
  cursor: pointer;
  &.simple-select-option--focused {
    background-color: $colorBackgroundShade1 !important;
  }
  &.simple-select-option--selected {
    background-color: $colorBackgroundShade1 !important;
  }
}

.button-error-box {
  position: absolute;
  bottom: 0;
}

.custom-input-wrapper .input-label {
  @media (min-width: $desktop) {
    width: 28%;
    position: relative;
    top: 14px;
  }
}

.custom-input-wrapper-error {
  display: flex;
  color: $colorTextWarning;
  font-size: 9pt;
  transition: all 1s ease-in-out;
  @media (min-width: $desktop) {
    margin-left: 29%;
  }
}

.custom-input-error {
  border-color: $colorBorderTextWarning !important;
  outline-color: $colorBorderTextWarning;
}
.date-input-date-container {
  display: flex;
  width: 100%;
  @media (min-width: $desktop) {
    width: 71%;
  }
}

.date-input-field {
  display: flex;
  width: 33%;
  margin-right: 10px;
}

.date-input-field-year {
  display: flex;
  width: 34%;
}

.mobile {
  @media (min-width: $desktop) {
    display: none !important;
  }
}

.desktop {
  @media (max-width: $desktop) {
    display: none !important;
  }
}

.layout-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.redirect-to-booked-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.redirect-to-booked-layout-wrapper {
  height: 100%;
}
