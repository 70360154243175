.Select-control {
  // antd
  border: none !important;
  // background-color: transparent !important;
}

.select-transparent .Select-control {
  background-color: transparent !important;
}

.select-desktop-wide .Select-menu-outer {
  min-width: 300px !important;
  // background: $color-text-warning !important;
}

.select-styled {
  border: 1px solid $colorBorderBackgroundShade2;
  border-radius: 3px;
  background-color: $colorBackgroundLightest;
  box-shadow: inset 2px 2px 7px 0 $colorBackground;
}

.select-placeholder {
  position: relative;
  left: -10px;

  .Select-placeholder {
    // padding-left: 0px;
  }

  .header-search-form-inner-field-value {
    margin-left: 10px;
  }
}

.pointer {
  cursor: pointer;
}

.noselect {
  user-select: none;
}

.link {
  color: $colorTextLink;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.text-right {
  text-align: right;
}

.only-mobile {
  @media (min-width: $desktop) {
    display: none;
  }
}

.only-desktop {
  display: none;
  @media (min-width: $desktop) {
    display: inherit;
  }
}

.only-desktop-span {
  display: none;
  @media (min-width: $desktop) {
    display: inline;
  }
}

.DayPicker-Month {
  margin-right: 0 !important;
}

.DayPicker-Months {
  align-items: flex-start;

  @media (min-width: $desktop) {
    flex-wrap: nowrap !important;
  }
}

.DayPicker-Month:first-child {
  @media (min-width: $desktop) {
    margin-right: 30px !important;
  }
}

.DayPicker-Month:nth-child(3) {
  @media (min-width: $desktop) {
    margin-left: 30px !important;
  }
}

.DayPicker-wrapper {
  outline: none;
}

// rc-tooltip
.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}

.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-play-state: paused;
}

.rc-tooltip-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  animation-play-state: paused;
}

.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  animation-name: rcToolTipZoomIn;
  animation-play-state: running;
}

.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  animation-name: rcToolTipZoomOut;
  animation-play-state: running;
}

@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}

@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}

.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  opacity: 1;
  border: none;
  transition: opacity 0.5s;
  animation: fadein 0.5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.rc-tooltip-hidden {
  // opacity: 0;
  display: none;
  // border: 5px solid $color-text-warning;
  opacity: 0;
  transition: opacity 0.5;
}

.rc-tooltip-hidden .rc-tooltip-inner {
  display: none;
}

.rc-tooltip-inner {
  padding: 10px;
  color: $colorTextLightest;
  text-align: left;
  text-decoration: none;
  background-color: $colorBackgroundTooltip;
  border-radius: 6px;
  box-shadow: 0 0 4px #{$colorBackgroundDarkest}2b;
}

.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  display: none;
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}

.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}

.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}

.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}

.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}

.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}

.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}

.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}

.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}

.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}

.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}
// own
.Select-control:hover {
  box-shadow: none !important;
}

.Select.is-focused:not(.is-open) > .Select-control {
  box-shadow: none !important;
}

.react-phone-number-input__input {
  border: none !important;
}

.phone-input-form {
  width: 100% !important;
  height: 45px !important;
  border: none !important;
  box-shadow: $colorBackground 2px 2px 7px 0px inset;
  @media (min-width: $desktop) {
    //width: 400px !important;
  }
}

.phone-input {
  width: 100% !important;
  height: 45px !important;
  border: none !important;
  box-shadow: $colorBackground 2px 2px 7px 0px inset;
}

.bold {
  font-weight: bold;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: $colorBackgroundProgress;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px $colorBackgroundProgress,
    0 0 5px $colorBackgroundProgress;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: $colorBorderProgress;
  border-left-color: $colorBorderProgress;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.left {
  text-align: left;
}

.color-warning {
  color: $colorTextWarning;
}

.react-tel-input {
  width: auto; //  !important; removed because of dynam parameters
  display: flex;
  flex-grow: unset;
  box-shadow: inset 2px 2px 7px 0 $colorBackground;
  border: 1px solid $colorBorderInputUnfilled;
  border-radius: 3px;
  box-sizing: border-box;

  @media (min-width: $desktop) {
    flex-grow: 1;
  }

  &.react-tel-input--error {
    border: 1px solid $colorBorderTextWarning !important;
  }

  &.react-tel-input--filled {
    border: 1px solid $colorBorderInput !important;
  }

  .selected-flag {
    position: absolute;
    width: 38px;
    height: 100%;
    padding: 0 0 0 8px;
    border-radius: 3px 0 0 3px;
    z-index: 0 !important;
    border-color: $colorBorderInput;
  }

  .country-list {
    margin-top: 1px !important;
  }

  .flag-dropdown {
    background-color: $colorBackgroundLightest !important;
    border: none !important;
    box-shadow: $colorBackground 2px 2px 7px 0px inset !important;
  }
}

#register-form .react-tel-input {
  @media (min-width: $desktop) {
    max-width: 400px;
  }
}

.register-telephone-wrapper {
  margin-bottom: 5px;
}

.register-telephone-wrapper .custom-input-wrapper {
  height: 47px;
}
