/**
Color palette
 */
$color-pure-black: #000000;
$color-darkest: #000001;
$color-darkest-02: #000002;
$color-darkest-03: #000003;

$color-dark-gray-01: #333332;
$color-dark-gray-02: #4e505e;
$color-dark-gray-03: #4e505f;
$color-dark-gray-04: #555554;
$color-dark-gray-05: #808080;
$color-dark-gray-06: #808081;

$color-light-gray-01: #959595;
$color-light-gray-02: #9F9F9F;
$whitesmoke: whitesmoke;

$color-light-red-01: #ff6666;
$color-light-red-02: #ff0001;
$color-light-red-03: #ff0002;
$color-light-red-04: #ff0100;

$color-dark-red-01: #e60000;
$color-dark-red-02: #d0021b;

/**
Colors according to usage
 */
//< BackgroundsShadowsFilters >//
$colorBackground: #eef3f6;
$colorBackgroundShade1: #e3edf3;
$colorBackgroundShade2: #c0d9e8;
$colorBackgroundPopover: #131f6a;
$colorBackgroundTooltip: #131f6e;
$colorBackgroundAdditionalDark: #4e505e;
$colorBackgroundAdditionalDark2: #333332;
$colorBackgroundDarkest: #000003;
$colorBackgroundLightest: #fffffc;
$colorBackgroundGrayLight: #F7F7F7;
$colorBackgroundFooterPayments: #f6f9fa;
$colorBackgroundLanguageSelection: #f6f9fb;
$colorBackgroundBestOffer: #34bb84;
$colorBackgroundWarning: #ff0100;
$colorBackgroundWarningLight: #fff2ec;
$colorBackgroundDanger: #fd4830;
$colorBackgroundSuccess: #78b947;
$colorBackgroundSuccessLight: #e4eee6;
$colorBackgroundChooseDate: #e85e23;
$colorBackgroundLink: #e85e18;
$colorCheckboxBackground: #fffffd;

//< ProgressBar >//
$colorTextProgressBar: #e85e20;
$colorBackgroundProgressBarDark: #e85e19;
$colorBackgroundProgressBarLight: #ffe3d7;

//< Texts >//
$colorTextAdditionalLight: #9ea0ac;
$colorTextAdditionalLight2: #e9e9e7;
$colorText: #131f6b;
$colorTextAdditionalDark: #4e505f;
$colorTextDarkest: #000001;
$colorTextLightest: #fffffe;
$colorTextWhite: #ffffff;
$colorTextDayPickerToday: #d0021b;
$colorTextWarning: #ff0002;
$colorTextSuccess: #78b946;
$colorTextDanger: #fd4831;
$colorYellow01: #EBC032;

//< BordersOutlines >//
$colorBorderBackground: #eef3f8;
$colorBorderBackgroundShade1: #e3edf4;
$colorBorderBackgroundShade2: #c0d9e9;
$colorBorderFiltersSeparator: #c0d9ea;
$colorBorderText: #131f6c;
$colorBorderTextWarning: #ff0001;
$colorBorderAdditionalLight: #9ea0ad;
$colorBorderAdditionalLight2: #e9e9ea;
$colorBorderDarkest: #000002;
$colorBorderInput: #939fbc;
$colorBorderInputUnfilled: #c0d9e6;
$colorBorderSearchFormAdditional: #c5dbe9;
$colorBorderAdvancedParameters: #f6f9fc; // technically it's background
$colorBorderLink: #e85e22;

//< GenericShadow >//
$colorBoxShadow: #ecf1f4;
$colorBackgroundAdditionalLight: #9ea0ab;

//< Links >//
$colorTextLink: #e85e21;
$colorTextDarkLink: #4e505e;

//< Gradients >//
$colorMainButtonGradientStart: #ff8531;
$colorMainButtonGradientEnd: #db4a17;

//< Progress >//
$colorBackgroundProgress: #2299dd;
$colorBorderProgress: #2299df;

//< Slider >//
$colorBackgroundSlider: #57c5f7;
$colorBorderSliderHover: #57c5f9;
$colorBackgroundSliderLighter: #96dbfa;
$colorWithinRangeSlider: #131f6d;
$colorOutOfRangeSlider: #c0d9e7;
$colorBorderSlider: #e85e24;
$colorBackgroundTooltipSlider: #555554;

//< Table >//
$colorTablePaginationBackground: #e85e27;
$colorTablePaginationHoverBorder: #e85e25;
$colorTablePaginationButtonText: #e85e26;
$colorTablePaginationButtonTextActive: #eef3f9;
$colorTablePaginationBorder: #f0f1f3;
$colorTablePaginationDisabled: #808081;

//< Navigation >//
$colorNavigationLine: #131f6f;

//BrandedFares
$colorYellowBackground: #FFB715;

// Reservaions table
$statusMerelyOkColor: #DA7909;
$statusMerelyOkBackground: #FDF3C7;
$statusInfoColor: #717171;
$statusInfoBackground: #e2e2e2;
$statusProblemColor: #E11F73;
$statusProblemBackground: #FDF2F8;