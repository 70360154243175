.alert {
  border-radius: 2px;
  padding: 12px;
  font-size: 13px;
}

.alert-success {
  color: $colorTextSuccess;
  background-color: $colorBackgroundSuccessLight;
}

.alert-warning {
  color: $colorTextWarning;
  background-color: $colorBackgroundWarningLight;
}

.alert-warningWithBlackText {
  color: $colorTextDarkest;
  background-color: $colorBackgroundWarningLight;
}

.alert-info {
  background-color: $colorBackground;
}

.simple-radio-input {
  font-size: 14px;
  display: flex;
  input[type="radio"] {
    display: none;
  }
  .simple-radio-input__styled {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 22px;
    height: 22px;
    background-color: $colorBackgroundLightest;
    border-radius: 50%;
    border: 1px solid $colorBorderBackgroundShade2;
    margin-right: 10px;
  }
  .simple-radio-input__styled-inner {
    background-color: $colorBackgroundLink;
    border-radius: 50%;
    width: 14px;
    height: 14px;
  }
}

.modal-alert-label {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 32px 32px 0 32px;
}

.modal-alert-content {
  margin: 8px 32px 24px 32px;
}

.modal-alert-buttons {
  display: flex;
  padding-top: 5px;
  border-top: 1px solid $colorBorderBackgroundShade1;

  @media (max-width: $desktop) {
    display: flex;
    flex-direction: column;
  }

  .higlighted {
    background: linear-gradient(
      143.27deg,
      $colorMainButtonGradientStart 0%,
      $colorMainButtonGradientEnd 100%
    );
    color: $colorTextLightest;
    cursor: pointer;
    border: none;
    border-radius: 5px;
  }
}

.code-confirmation-buttons {
  width: 250px !important;
}

.email-confirmation-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.modal-alert-buttons-wrapper {
  padding-top: 20px;
}

.modal-alert-button {
  flex: 1;
  height: 40px;
  text-align: center;
  line-height: 40px;
  cursor: default;
  @media (min-width: $desktop) {
    &:not(:first-child) {
      border-left: 1px solid $colorBorderBackgroundShade1;
    }
  }
  @media (min-width: $desktop) {
    cursor: pointer;
  }
}

/*
  SWITCH
*/

.switch {
  position: relative;
  display: flex;
  height: 36px;
  border-radius: 8px;
  background: #eae9e9;
  border-radius: 16px;
  width: fit-content;
}

.switch-item {
  padding: 8px 16px;
  font-size: 0.8rem;
  display: grid;
  place-content: center;
  z-index: 99;
  transition: color 0.3s;
  cursor: default;
  @media (min-width: $desktop) {
    cursor: pointer;
  }
}

.switch-incidator {
  position: absolute;
  background: $colorMainButtonGradientStart;
  border-radius: 16px;
  height: 100%;
  width: 100px;
  transition: transform 0.3s, width 0.3s;
  box-shadow: 0 0px 0.5px rgba(0, 0, 0, 0.019), 0 0px 1.1px rgba(0, 0, 0, 0.028),
    0 0px 2px rgba(0, 0, 0, 0.034), 0 0px 3.1px rgba(0, 0, 0, 0.04),
    0 0px 4.8px rgba(0, 0, 0, 0.046), 0 0px 7.5px rgba(0, 0, 0, 0.052),
    0 0px 12.5px rgba(0, 0, 0, 0.061), 0 0px 25px rgba(0, 0, 0, 0.08);
}

// Share modal
.share-modal {
  padding: 16px 24px 32px 24px;
  @media (min-width: $desktop) {
    min-width: 480px;
  }
}

.share-modal-toggle {
  cursor: pointer;
  font-size: 0.9rem;
  color: $colorText;
}

.share-modal-title {
  margin-bottom: 24px;
  user-select: none;
  color: $colorText;
}

.share-modal-socials {
  margin-bottom: 24px;
}

.react-share__ShareButton {
  margin-right: 16px;
}

.share-modal-social-description {
}

.share-modal-social-description-label {
  font-size: 0.9rem;
  user-select: none;
  color: $colorText;
}

.share-modal-clipboard {
  display: flex;
  align-items: center;
  background: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  margin-bottom: 24px;
}

.share-modal-clipboard-input {
  flex: 1;
  padding: 12px 0 12px 16px;
  outline: none;
  border: none;
  background: transparent;
  font-size: 0.8rem;
  color: $colorTextDarkest;
  user-select: all;
  color: $colorText;
}

.share-modal-clipboard-button {
  font-size: 0.8rem;
  padding: 12px 16px;
  text-transform: uppercase;
  color: $colorTextLink;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}

.share-modal-aditionalinfo-wrapper {
  width: fit-content;
}

.modal-action-cell {
  display: table-cell;
  vertical-align: middle;
  width: 35px;
}

.modal-action-btn {
  display: inline-block;
  text-align: center;
  color: $colorBackgroundLink;
  padding-top: 3px;
  width: 22px;
  height: 22px;
  cursor: pointer;
}
