.booked {
  text-align: center;
  padding-top: 70px;
}
.booked-h {
  display: block;
  font-size: 20px;
  margin-top: 10px;
}
.booked-description {
  font-size: 14px;
  margin-top: 30px;
  display: block;
  @media (min-width: $desktop) {
    padding-left: 35px;
    padding-right: 35px;
  }
}
.booked-back {
  cursor: pointer;
  color: $colorTextLink;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid $colorBorderLink;
  padding: 17px 48px;
  background: transparent;
  margin-top: 40px;
}

.counter-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: 40px;
  align-items: center;
}

.counter-sign {
  padding: 0px 10px;
  cursor: pointer;
}
.counter-sign-disabled {
  cursor: not-allowed;
}

.booked-search-steps {
  padding-bottom: 40px;
}
.counter-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: 40px;
  align-items: center;
}
