.result-h3 {
  display: inline-block;
  margin-left: 10px;
  margin-top: 20px;
  display: inline-block;
  align-items: center;
  justify-content: space-between;
}
.result-h3-extra-wrapper {
  display: flex;
  align-items: center;
}
.result-h3-extra-link {
  color: $colorTextLink;
  font-size: 13px;
  margin-right: 25px;
  text-decoration: underline;
  font-weight: normal;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}
.result-h3-extra-button {
  font-size: 13px;
  font-weight: bold;
  padding: 6px 10px;
  background-color: $colorBackgroundLightest;
  color: $colorBorderLink;
  border: 1px solid $colorBorderLink;
  border-radius: 4px;
  min-width: 144px;
  cursor: pointer;
}
.result-h4 {
  display: inline-block;
  margin-bottom: 0;
}
.result-box {
  border-radius: 5px 5px 0 0;
  box-sizing: border-box;
  padding: 15px 10px;
  background: $colorBackgroundLightest
    linear-gradient(230deg, transparent 20px, $colorBackgroundLightest 0);
}

.result-box-description {
  @extend .result-box;
  margin: 0px !important;
  padding: 0px !important;
  margin-bottom: 15px !important;
  border-radius: 5px;
}

.result-box-padding-none {
  padding: 0px;
}

.result-box-padding-sm {
  padding: 15px 10px;
}

.result-box-padding-md {
  padding: 20px 25px;
}

.result-box-padding-lg {
  padding: 35px 40px;
}

.result-box-comment {
  padding-right: 28px;
  display: flex;
}

.input-wrapper {
  margin-bottom: 15px;
  @media (min-width: $desktop) {
    display: flex;
    margin-left: 0px;
  }
}
.input-wrapper .input-label {
  @media (min-width: $desktop) {
    width: 28%;
    position: relative;
    top: 14px;
  }
}

.input-label {
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 18px;
  display: block;
  margin-bottom: 10px;
  transition: color 0.3s ease-in-out;
  width: 100%;
  @media (min-width: $desktop) {
    width: inherit;
  }
  padding-right: 5px;
}
.input-wrapper-required .input-label {
  font-weight: bold;
}
.input-input {
  border: 1px solid $colorBorderBackgroundShade2;
  border-radius: 3px;
  background-color: #ffffff !important;
  box-shadow: inset 2px 2px 7px 0 $colorBackground;
  height: 45px;
  width: 100%;
  font-size: 13px;
  padding-left: 12px;
  box-sizing: border-box;
  outline-width: 2px;
  display: flex;
  @media (min-width: $desktop) {
    width: inherit;
    flex-grow: 1;
    max-width: 400px;
  }
}
.input-input:not(:placeholder-shown):not(.error),
.form-textarea-field:not(:placeholder-shown):not(.error) {
  border-color: $colorBorderInput;
}
.input-input::placeholder {
  color: $colorTextAdditionalLight;
}
.input-wrapper-error {
  .input-label {
    color: $colorTextWarning;
    transition: all 0.3s ease-in-out;
  }
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.items-center {
  align-items: center;
}

.content-center {
  justify-content: center;
}

.result {
  padding-top: 20px;
  @media (min-width: $desktop) {
    display: flex;
  }
}
.result-warning {
  margin-top: 36px;
  padding: 15px 20px 15px 15px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 5px;
  background-color: $colorBackgroundLightest;
}
.result-information {
  @media (min-width: $desktop) {
    flex-grow: 1;
    margin-right: 30px;
  }
}
.result-summary {
  display: none;
  @media (min-width: $desktop) {
    display: block;
    position: sticky;
    top: 0px;
    height: 600px;
    width: 350px;
  }
}
.result-summary-show {
  display: block;
}

.payment-modal-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.payment-modal-text-center {
  width: 100%;
  text-align: center;
  margin-left: 0px !important;
}

.payment-modal-summary-result-price {
  margin-left: 0px;
}

.payment-modal-detail-inner {
  width: 300px;

  @media (min-width: $desktop) {
    width: 560px;
  }
}

.payment-modal-detail-button {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.result-box-about {
  margin-bottom: 28px;
}
.result-box-description-first {
  @media (min-width: $desktop) {
    margin-top: 17px;
  }
}
.result-box-description {
  flex-grow: 1;
  margin-left: 15px;
  margin-right: 15px;
}

.result-box-description-paymethod {
  flex-grow: 1;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: anywhere;
}

.reservation-fail-payments-box {
  width: 100%;
  text-align: center;
  margin-top: 40px;

  @media (min-width: 768px) {
    min-width: 60%;
    max-width: 570px;
  }
}

.result-confirmation {
  display: flex;
  flex-direction: column;
  border: 1px solid $colorBorderLink;
  border-radius: 3px;
  background-color: $colorBackgroundLightest;
  margin-top: 5px;
  @media (min-width: $desktop) {
    align-items: center;
    flex-direction: row;
  }
}

.result-confirmation-wrapper {
  display: flex;
  align-items: center;
  @media (min-width: $desktop) {
    margin-right: 60px;
    width: 500px;
  }
}

.result-confirmation-checkbox-wrapper {
  background: $colorCheckboxBackground;
  width: 22px;
  height: 22px;
  border-radius: 4px;
}

.result-confirmation-checkbox {
  width: 22px;
  height: 22px;
}

.result-confirmation-text {
  margin: 3px 10px 0px 10px;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 18px;
  @media (min-width: $desktop) {
    margin-top: 2px;
    line-height: inherit;
    flex-grow: 1;
    margin-right: 30px;
    display: flex;
    align-items: center;
  }
}
.result-confirmation-text-red {
  color: $colorTextWarning;
}

.button-result-finish {
  border-radius: 4px;
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 0.84px;
  line-height: 20px;
  text-transform: uppercase;
  height: 70px;
  width: 100%;
  margin-top: 20px;
  @media (min-width: $desktop) {
    width: inherit;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 0;
    max-width: 500px;
    overflow: hidden;
    margin-left: 50px;
  }
}
.result-insurance-description-circle {
  width: 55px;
  height: 55px;
}
.erv {
  margin: 10px;
  font-weight: bold;
}
.result-insurance-circle {
  height: 24px;
  width: 24px;
}

.result-box-insurance-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid $colorBorderBackgroundShade2;
  }

  @media (min-width: $desktop) {
    cursor: pointer;
  }
}

.result-box-insurance-one-content {
  display: flex;
  align-items: center;

  [type="radio"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  [type="radio"] + img {
    cursor: pointer;
  }
}

.result-box-insurance-one-price {
  font-size: 12px;
  color: gray;
  height: 32px;
  line-height: 32px;
  text-align: right;
  white-space: nowrap;
}

.noborder {
  border: none;
}
.result-date-birth {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  max-width: 400px;
}
.result-date-birth-date {
  display: flex;
  flex-grow: 1;
  margin-right: 10px;
}
.result-payment-card-line {
  display: flex;
  margin: 5px 5px 20px 0;
  justify-content: center;
  align-items: center;
}
.result-payment-card {
  margin-right: 15px;
}
.result-payment-card-description {
  flex-grow: 1;
  //padding-top: 4px;
  font-size: 14px;
  color: $colorText;
  //position: relative;
  //top: 10px;
}
.result-h4 {
  color: $colorText;
  font-size: 12px;
  text-transform: uppercase;
}

.result-summary-prices {
  text-align: center;
}
.result-summary-prices-h {
  font-size: 15px;
}
.result-summary-prices-price {
  font-size: 22px;
  margin: 10px 0;
  display: inline-block;
}
.result-summary-prices-dph {
  margin-top: 16px;
  font-size: 14px;
  color: $colorTextAdditionalLight;
}
.result-salutation {
  max-width: 130px;
  @media (min-width: $desktop) {
    max-width: inherit;
    width: 200px;
  }
}
.result-flight-info-details {
  margin: 10px 0 10px 0;
}

.result-flight-info-details-links {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin: 20px 10px 10px 10px;
  user-select: none;
}

.additional-detail-section-links-wrapper {
  padding: 5px 0;
}

.result-flight-info-details-technical-stops {
  margin: 10px 10px 20px 10px;
  font-size: 14px;
  line-height: 1.5;
}

.result-flight-info-add-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: relative;
  top: 5px;
}

.result-payments-wrapper {
  display: flex;
  flex-direction: column;
}

.result-payments-subwrapper {
  width: 100%;
}

.result-payments-list {
  display: flex;
  flex-direction: column;
}

.search-steps-container {
  text-align: center;
  padding: 5px 0px 25px 0px;
  max-width: 1010px;
  margin: auto;
  height: 1rem;
  @media (max-width: $desktop) {
    display: none;
  }
}

.search-steps-mobile-container {
  @media (min-width: $desktop) {
    display: none;
  }
}

.search-steps-mobile-container-hotels {
  margin-bottom: 14px;
}

.Select-arrow {
  border-color: $colorBorderLink transparent transparent !important;
}

.result-box .Select-placeholder {
  font-size: 13px;
  color: $colorTextAdditionalLight;
  margin-top: 1px;
}

.terms-and-conditions-container {
  min-height: 400px;
  min-width: 450px;
  padding: 25px;
  font-family: "Times New Roman", Times, serif;
}

.loaylty-program-input {
  line-height: 30px;

  @media (min-width: $desktop) {
    width: 400px;
  }
}

.result-summary-box {
  min-width: 350px;
  border-radius: 5px 5px 0 0;
  box-sizing: border-box;
  padding: 39px 30px;
  margin-bottom: 15px;
  background: $colorBackgroundLightest
    linear-gradient(135deg, transparent 20px, $colorBackgroundLightest 0);
}

.result-summary-box-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  line-height: 31px;
  letter-spacing: 0.09px;
}

.result-summary-box-labels {
  padding-top: 4.5px;
  overflow-wrap: break-word;
  padding-bottom: 4px;
  line-height: 20px !important;
}

.result-summary-box-divider {
  margin: 11px 0px 8px 0px;
  height: 1px;
  background-color: $colorBackgroundShade2;
}

.result-summary-box-price {
  white-space: nowrap;
  padding-left: 4px;
  float: right;
  font-size: 14px;
  line-height: 31px;
}

.custom-input-container {
  margin-left: none;
  flex-grow: 1;
  max-width: 97%;
  flex-wrap: wrap;
  @media (min-width: $desktop) {
    max-width: 565px;
  }
}

.result-form-box {
  position: relative;
  border-radius: 5px;
  background-color: $colorBackgroundLightest;
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 15px;
  @media (min-width: $desktop) {
    padding: 15px 30px;
    width: 630px;
  }
}

.result-form-box-wrapper {
  background-color: $colorBackgroundLightest;
  border-radius: 5px;
  margin-bottom: 20px;
}

.has-footer-section {
  margin-bottom: 3px !important;
}

.result-promocodes-form {
  display: flex;
}

.result-promocodes-form-code {
  flex: 1;
}

.result-promocodes-form-code-field {
  width: 100%;
}

.result-promocodes-form-button-button {
  height: 45px;
  margin-left: 5px;
  width: 100px;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.result-promocodes-description {
  display: block;
  margin-bottom: 5px;
}

.result-promocodes-apply {
  border-radius: 3px;
  padding: 10px;
  margin-top: 10px;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(360deg);
  }
}

.result-promocodes-form-button-loader {
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

.field-disabled {
  background-color: $whitesmoke !important;
}

.result-promocodes-apply-error {
  background: $colorBackgroundWarningLight;
}

.result-promocodes-apply-success {
  background: $colorBackgroundSuccessLight;
}

.form-text-input-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100px;
  @media (min-width: $desktop) {
    height: 70px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.form-text-input-container-radio-list {
  display: flex;
  flex-direction: column;
}

.form-marketplace-radio-input-wrapper {
  padding-right: 15px;
}

.form-marketplace-radio-info-wrapper {
  flex: 2;
  padding: 3px 6px 3px 6px;

  .radio-label {
    font-size: 14px;
    font-weight: bold;
    color: $colorText !important;
  }

  .radio-description {
    margin-top: 5px;
    font-size: 14px;
  }

  &.favorite {
    border-radius: 10px;
    background-color: rgba($colorTextProgressBar, 0.1) !important;
    border: 0.5px solid $colorTextProgressBar !important;
  }
}

.form-marketplace-radio-container {
  display: flex;
  padding-top: 15px;
  flex-direction: column;

  @media (min-width: $desktop) {
    padding-top: 35px;
  }

  .radio-price {
    text-align: right;
    width: 90px;
    font-weight: bold;
  }
}

.form-marketplace-row-wrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
  padding-bottom: 15px;
  cursor: pointer;
  padding: 5px 5px;
  margin-bottom: 5px;

  .favorite-badge {
    position: absolute;
    top: -7px;
    right: 110px;
    border-color: #ffb715;
    color: #ffffff;
    background: #ffb715;
    border: 1px solid;
    border-radius: 3px;
    display: inline-block;
    font-size: 12px;
    padding: 1px 4px 2px 4px;
  }
}

.form-section-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px 5px 0 0;
  backdrop-filter: blur(2px);
  top: 0;
  left: 0;
  z-index: 5;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-section-loader-wrapper {
  position: absolute;
  display: flex;
  margin-top: 5px;
  margin-right: 5px;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.flight-additional-info-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0 0 5px 5px;
  backdrop-filter: blur(2px);
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-section-description {
  padding: 10px 0 25px 0;

  @media (min-width: $desktop) {
    padding: 10px 0 15px 0;
  }
  font-size: 14px;
}

.form-text-input-container-uneditable-name {
  margin-bottom: 20px;
  height: 70px;
  border-bottom: 1px solid $colorBorderAdditionalLight2;

  @media (min-width: $desktop) {
    height: 49px;
  }
}

.form-text-input-container-search-guest {
  margin-bottom: 10px;
  height: 70px;
  border-bottom: 1px solid $colorBorderAdditionalLight2;
  border: none;

  @media (min-width: $desktop) {
    height: 49px;
  }
}

.padding-bottom-none {
  padding-bottom: 0px;
}

.form-date-wrapper {
  width: 100%;
  display: flex;
  @media (min-width: $desktop) {
    width: 403px;
  }
}

.form-date-wrapper input {
  @media (min-width: $desktop) {
    width: 133px;
    margin-right: 3px;
  }
}

.form-text-input-label {
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 18px;
  display: block;
  transition: color 0.3s ease-in-out;
  padding: 5px 5px 10px 0;
  width: 100%;
  &.error {
    color: red;
  }
  &.required {
    font-weight: bold;
  }
  @media (min-width: $desktop) {
    top: 0;
    min-width: 159px;
    width: 159px;
  }
}

.form-text-input-uneditable-name-label {
  margin-top: 10px;
  @media (min-width: $desktop) {
    margin-top: 0px;
  }
}

.form-text-input-search-guest-new-label {
  color: $colorTextLink;
  cursor: pointer;
  text-decoration: underline;
  font-size: 13px;

  &:hover {
    text-decoration: none;
  }
}

.form-text-input-uneditable-name {
  font-weight: bold;

  margin-top: -10px;

  @media (min-width: $desktop) {
    margin-top: 13px;
  }
}

.form-text-input-field {
  flex-grow: unset;
  border: 1px solid $colorBorderInputUnfilled;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: inset 2px 2px 7px 0 $colorBackground, 0 0 3px $colorBackground;
  height: 45px;
  font-size: 13px;
  padding-left: 12px;
  box-sizing: border-box;
  display: flex;
  outline-width: 0px;

  &:focus {
    border-color: #131f6b;
  }
  &.filled {
    border: 1px solid #131f6b;
  }
  &:not(:placeholder-shown) {
    border-color: $colorBorderInput;
  }
  &.error {
    border-color: red;
    outline-color: red;
  }
  @media (min-width: $desktop) {
    flex-grow: 1;
    // max-width: 400px;
  }
}

.booking-form-element-error .form-text-input-label {
  color: red;
}
.booking-form-element-error .form-text-input-field {
  border-color: red;
  outline-color: red;
}
.booking-form-element-error .custom-select-control {
  border-color: red !important;
  outline-color: red !important;
}
.booking-form-element-error .react-tel-input {
  border-color: red !important;
}

.form-text-input-error {
  width: 100%;
  color: red;
  font-size: 9pt;
  transition: all 1s ease-in-out;

  @media (min-width: $desktop) {
    margin-left: 29%;
  }
}

.form-text-input-error-radio {
  @extend .form-text-input-error;
  @media (min-width: $desktop) {
    margin-left: 0;
  }
}

.form-textarea-container {
  display: flex;
}

.form-textarea-field {
  height: 155px;
  width: -webkit-fill-available;
  padding: 10px 15px;
  font-size: 10pt;
  border: 1px solid #c0d9e8;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: inset 2px 2px 7px 0 #eef3f6;
  flex-grow: 1;
}

.form-radio-container {
  display: flex;
  flex-wrap: wrap;
  height: 75px;
  @media (min-width: $desktop) {
    height: 70px;
  }
}

.form-radio-buttons-container {
  display: flex;
  flex-grow: 1;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  justify-content: space-around;
  align-items: center;
  padding: 0px 40px;
}

.form-radio-button {
  display: flex;
}

.form-composite-wrapper {
  display: flex;
  flex-wrap: wrap;
  height: 100px;
  @media (min-width: $desktop) {
    height: 70px;
  }
}

.form-country-code-input {
  @extend .form-text-input-field;
  width: 20%;
  margin-right: 10px;
  flex-grow: 0;
}

.form-date-containner {
  display: flex;
  flex-wrap: wrap;
  height: 100px;
  @media (min-width: $desktop) {
    height: 70px;
  }
}

.bookingPriceConfirmation {
  padding: 30px 24px;
  color: $colorText;
  max-width: 790px;
  height: fit-content;

  .heading {
    font-size: 24px;
    margin: 0 0 24px 0;
  }

  .prices-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $colorBackgroundFooterPayments;
    padding: 24px 16px;
    margin-top: 24px;
    @media (max-width: $desktop) {
      flex-wrap: wrap;
      justify-content: space-evenly;
      & > * {
        margin: 0 0 10px !important;
      }
    }
  }

  .price-group {
    display: flex;
    flex-direction: column;
    margin-right: 32px;
  }

  .price {
    font-size: 22px;
    font-weight: bold;
    padding: 4px;
  }

  .price-label {
    text-align: center;
    margin-top: -4px;
    font-size: 12px;
  }

  .btn {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding: 0 24px;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    height: 38px;
    outline: none;
  }

  .confirm-btn {
    @extend .button-secondary-color;
    @extend .btn;
  }

  .search-btn {
    @extend .btn;
    border: none;
    color: $colorTextLink;
    background-color: transparent;
    margin-right: 16px;
  }
}

.strikethrough {
  position: relative;
}

.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;
  transform: rotate(-12deg);
}

.passenger-notification-row {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
}

.passenger-notification-row__passengers-wrapper {
  display: flex;
  font-weight: 800;
  margin-bottom: 4px;
}

.passenger-notification-row__passengers-title {
  margin-right: 4px;
}

.passenger-notification-row__message {
  margin-left: 16px;
}

.views-modal-warning {
  font-size: 15px;
  margin: 10px;
}

.views-modal-warning-background {
  @extend .views-modal-warning;
  background-color: $colorBackgroundLightest;
  margin: 10px 0;
  padding: 8px 8px;
  border-radius: 5px;
}

.views-modal-description {
  font-size: 15px;
}

.view-modal {
  max-width: 650px;
}

.views-modal-link {
  padding: 8px 0px;
}

.views-section {
  padding: 8px 0px;
}

.views-modal-section-content {
  font-size: 14px;
  padding: 7px;
}

.view-modal-links-container {
  margin-top: 10px;
}

.result-action-btn {
  display: inline-block;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  padding: 7px 5px 7px 5px;
  background-color: $colorBackgroundLightest;
  color: $colorBorderLink;
  border: 1px solid $colorBorderLink;
  border-radius: 4px;
  min-width: 144px;
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 22px;
  cursor: pointer;
}

.passenger_modal {
  .modal-inner {
    width: 790px;
    @media (max-width: $desktop) {
      width: 82%;
    }
  }
  .main__body {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 15px 15px;
    color: $colorText;
    height: fit-content;
    @media (max-width: $desktop) {
      padding: 0 0;
    }

    .main__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 22px;
      margin-bottom: 13px;
      font-weight: bold;
      padding: 0 10px;
    }

    .add-traveler {
      padding-top: 15px;
    }
  }
}

.mobile-width {
  width: 320px !important;

  @media (min-width: $desktop) {
    width: 420px !important;
  }
}

.booking-form-section-info-modal {
  width: 80%;
  z-index: 30;
  min-height: 100px;

  @media (min-width: $desktop) {
    width: 50%;
  }

  .modal-section-wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 22px;

    @media (min-width: $desktop) {
      padding-top: 0px;
    }

    &__error {
      text-align: center;
      margin-top: 40px;
    }
  }

  .modal-section-title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }

  .modal-section-form {
    padding: 35px 0 0 0;
  }

  .modal-section-error {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }

  .modal-section-loader::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 5px solid transparent;
    border-top-color: $colorTextProgressBar;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }

  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }

    to {
      transform: rotate(1turn);
    }
  }
}
